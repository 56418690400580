.view-application-tooltip {
    background-color: #fff;
    border: 1px solid #cecece;
    opacity: 1;
    border-radius: 10px;
    pointer-events: auto;
    color: #585858;
    font-weight: 500;
    padding: 0;
    z-index: 999;

    .tooltip-wrap {
        margin: 10px;

        ul {
            margin: 0;
            padding: 0;
            list-style-type: none;

            li {
                // padding: 0px 10px 0px 30px;
                width: 100%;
                position: relative;
                padding: 2px 10px;


                &:first-child {
                    border-radius: 10px 10px 0 0;
                }

                &:last-child {
                    border-radius: 0 0 10px 10px;
                }



                &:hover {
                    background-color: #f7f8fb;
                    color: $navy;

                    .btn-tooltip {
                        color: $navy;
                        // font-weight: 700;


                        &.move-to-saved:before {
                            @include arrow-save($navy);
                        }

                        &.view-app:before {
                            @include document($navy);
                        }

                        &.workflow-history:before {
                            @include history($navy);
                        }

                        &.comments:before {
                            @include comment($navy);
                        }
                    }
                }

                .btn-tooltip {
                    border: 0;
                    padding-left: 20px;
                    color: black;
                    font-weight: normal;
                    background: none;
                    outline: none;
                    text-transform: none;

                    &:before {
                        content: '';
                        position: absolute;
                        display: block;
                        width: 20px;
                        height: 20px;
                        background-repeat: no-repeat;
                        background-position: 0 0;
                        background-size: 15px;
                        left: 0;
                        top: 8px;
                    }

                    &.move-to-saved:before {
                        @include arrow-save(black);
                    }

                    &.view-app:before {
                        @include document(black);
                    }

                    &.workflow-history:before {
                        @include history(black);
                    }

                    &.comments:before {
                        @include comment(black);
                    }
                }
            }
        }
    }
}
