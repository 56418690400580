.toggle-switch.MuiFormHelperText-root,
.toggle-switch.MuiFormControl-root label {
  color: $gray-600;
}

.toggle-switch {
  $spacing: 4px;

  // field label above
  // fieldset .MuiFormLabel-root.MuiInputLabel-root {
  .MuiFormLabel-root,
  .MuiFormLabel-root.MuiInputLabel-root {
    display: block;
    margin-bottom: 4px;
    position: relative;
  }

  &.toggle-small {

    .MuiFormGroup-root,
    .MuiFormGroup-root.MuiFormGroup-row {
      max-width: 120px;


      ~.MuiInputBase-root {
        left: 128px;
        position: absolute;
        width: calc(100% - 128px);

        .MuiInputBase-input {
          width: calc(100% - 10px);
        }
      }
    }
  }

  &.toggle-dark {

    .MuiFormGroup-root,
    .MuiFormGroup-root.MuiFormGroup-row {
      background-color: #e7e7e7;
    }
  }

  // switch group
  .MuiFormGroup-root,
  .MuiFormGroup-root.MuiFormGroup-row {
    background-color: #f2f2f2;
    border-radius: 18px;
    box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.15);
    display: table;
    // margin-bottom: 16px;
    // margin-top: 3px;
    overflow: hidden;
    padding: 0 $spacing;
    position: relative;
    width: 100%;
    margin-top: 5px;

    &::after {
      clear: both;
      content: '';
    }

    // &::before {
    //   $spacing: 4px;
    //   position: absolute;
    //   content: '';
    //   top: $spacing;
    //   left: 0;
    //   margin-left: $spacing;
    //   width: calc(50% - (#{$spacing} * 2));
    //   height: calc(100% - (#{$spacing} * 2));
    //   background-color: #fff;
    //   border-radius: 14px;
    //   box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.15), 0 3px 1px -2px rgba(0, 0, 0, 0.12);;
    //   z-index: 1;
    //   transition: 0.3s;
    // }

    &.active-index-0 {
      &::before {
        left: 0;
      }
    }

    &.active-index-1 {

      // &::before {
      //   left: 50%;
      // }
      .MuiFormControlLabel-root {
        &::before {
          left: 100%;
          // margin-left: -4px;
        }
      }
    }

    &.active-index-2 {
      .MuiFormControlLabel-root {
        &::before {
          left: 200%;
          // margin-left: -4px;
        }
      }
    }

    &.active-index-3 {
      .MuiFormControlLabel-root {
        &::before {
          left: 260%;
          // margin-left: -4px;
        }
      }
    }

    &.active-index-4 {
      .MuiFormControlLabel-root {
        &::before {
          left: 360%;
          // margin-left: -4px;
        }
      }
    }

    &.active-index-5 {
      .MuiFormControlLabel-root {
        &::before {
          left: 400%;
          // margin-left: -4px;
        }
      }
    }
  }

  // Styles for Advanced Search Toggle
  &.toggle-five-opt {

    .MuiFormGroup-root,
    .MuiFormGroup-root.MuiFormGroup-row {
      &.active-index-0 {
        &::before {
          left: 0;
        }
      }

      &.active-index-1 {

        // &::before {
        //   left: 50%;
        // }
        .MuiFormControlLabel-root {
          &::before {
            left: 100%;
            // margin-left: -4px;
          }
        }
      }

      &.active-index-2 {
        .MuiFormControlLabel-root {
          &::before {
            left: 192%;
            // margin-left: -4px;
          }
        }
      }

      &.active-index-3 {
        .MuiFormControlLabel-root {
          &::before {
            left: 279%;
            // margin-left: -4px;
          }
        }
      }

      &.active-index-4 {
        .MuiFormControlLabel-root {
          &::before {
            left: 364%;
            // margin-left: -4px;
          }
        }
      }

      &.active-index-5 {
        .MuiFormControlLabel-root {
          &::before {
            left: 400%;
            // margin-left: -4px;
          }
        }
      }
    }
  }

  // checkbox
  .MuiButtonBase-root.MuiRadio-root.PrivateSwitchBase-root {
    display: none;

    &.Mui-checked {

      // label text
      ~.MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1 {
        color: $blue;
        font-weight: 700;
      }
    }
  }

  // label
  label.MuiFormControlLabel-root {
    //   display: inline-block;
    display: table-cell;
    margin: 0;
    // padding: 6px 4px;
    padding: 6px 4px;
    position: relative;
    text-align: center;
    vertical-align: middle;
    width: 50%;
    z-index: 10;
    min-width: 70px;
    //   float: left;

    &:first-child {
      &::before {
        background-color: #fff;
        border-radius: 14px;
        box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.15), 0 3px 1px -2px rgba(0, 0, 0, 0.12);
        content: '';
        height: calc(100% - (#{$spacing} * 2));
        left: 0;
        position: absolute;
        top: $spacing;
        transition: 0.3s;
        // margin-left: $spacing;
        // width: calc(50% - (#{$spacing} * 2));
        width: 100%;
        z-index: 1;
      }
    }

    &:first-child:nth-last-child(3),
    &:first-child:nth-last-child(3)~label {
      width: 33.333%;
    }

    // &:first-child:nth-last-child(2) {
    // // &:first-child:nth-last-child(2) ~ .MuiFormControlLabel-root {
    //   &::before {
    //     left: 50%;
    //   }
    // }

    // &:first-child:nth-last-child(3) {
    //   // &:first-child:nth-last-child(2) ~ .MuiFormControlLabel-root {
    //   &::before {
    //     left: 33.333%;
    //   }
    // }
  }

  // label text
  .MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1 {
    display: inline-block;
    font-family: 'Roboto Condensed', 'Roboto', sans-serif;
    font-size: 14px;
    line-height: 1.1em;
    position: relative;
    width: 100%;
    z-index: 15;
  }
}

.toggle-switch.toggle-position-absolute {
  position: absolute;
  max-width: 150px;
  top: -10px;
  right: 0;

  label.MuiFormControlLabel-root {
    padding: 2px 4px;

  }
}
