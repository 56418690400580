.modal-custom {
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn-modal {
    height: 34px;
    white-space: nowrap;
    // margin-right: 5px !important;
    background-color: $blue !important;
    text-transform: none !important;
}

.MuiBox-root.css-19kzrtu {
    padding: 0;
}

.MuiFormControl-root {
    width: 100%;
}

.MuiButtonBase-root.btn-close::before {
    right: 0;
}

.paper-custom {
    position: relative;
    width: 1200px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
    padding: 20px;
    z-index: 1;

    .MuiSelect-select.MuiSelect-select, textarea {
        background-color: #f2f2f2;
        ;
    }
}

.modal-box {
    width: 100%;
    padding: 10px;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
    border: 0;
    background: #f2f2f2;

    .add-btn {
        float: right;
    }
}

.select-box {
    margin-top: 20px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    padding: 10px;
    background-color: #fff;
    border-radius: 5px;

    .select-label {
        margin-bottom: 5px;
    }
}

.modal-section-marker {
    .modal-section-title {
        margin: 0;
        font-size: 20px;
    }

    .required-sign {
        position: relative;
        margin: 0;

        span {
            position: absolute;
            top: 2px;
            left: -15px;
            color: red;
        }
    }

    hr {
        margin-top: 0;
    }
}

.input-with-checkbox {
    position: relative;

    .checkbox {
        position: absolute;
        top: 0px;
        right: 0;

        label.MuiFormControlLabel-root.MuiFormControlLabel-labelPlacementEnd.css-j204z7-MuiFormControlLabel-root {
            margin: 0;
        }
        // span.MuiButtonBase-root.MuiCheckbox-root.MuiCheckbox-colorPrimary.PrivateSwitchBase-root.MuiCheckbox-root.MuiCheckbox-colorPrimary.Mui-checked.MuiCheckbox-root.MuiCheckbox-colorPrimary.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root {
        //     margin-right: 2px;
        // }
        .css-i4bv87-MuiSvgIcon-root {
            width: 0.6em;
            height: 0.6em;
        }

        .css-ahj2mt-MuiTypography-root {
            font-size: 1em;
        }
    }
}

// Select wrap styles
.select-options-wrap {
    // border: 1px solid black;
    border: 1px solid #f2f2f2;
    background-color: #f2f2f2;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.24), 0px 2px 2px 0px rgba(0, 0, 0, 0.15);
    border-radius: 20px;
    padding: 10px;

    input {
        background-color: #fff;
    }

    .btns-right {
        margin-right: 43px;

        .btn:before {
            top: 9px;
        }
    }

    .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
        background-color: #fff;
        box-shadow: none;
        border: 1px solid #E7E6E2;
    }
}

// space between selects
.MuiFormControl-root.MuiTextField-root.mb-3.css-1u3bzj6-MuiFormControl-root-MuiTextField-root:first-child,
.MuiFormControl-root.css-1nrlq1o-MuiFormControl-root:first-child {
    margin-right: 10px;
}

// section size
// .MuiTabs-scroller.MuiTabs-hideScrollbar.MuiTabs-scrollableY.css-1cgx7kx-MuiTabs-scroller {
//     min-width: 400px;
//     padding-right: 20px;
//     padding-left: 20px;
// }

// tabs align

.MuiTabs-flexContainer.MuiTabs-flexContainerVertical.css-lfwcke-MuiTabs-flexContainer button + button,
button + .MuiButtonBase-root {
    margin-left: 0;
    text-align: left;
}

.MuiTabs-root.MuiTabs-vertical.css-35zqo5-MuiTabs-root .MuiTabs-flexContainer.MuiTabs-flexContainerVertical.css-lfwcke-MuiTabs-flexContainer button {
    align-items: baseline;
}

.modal-section-box {
    position: relative;
    padding: 20px;
    // height: 643px;
    // overflow: auto;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);

    .search-box {
        min-width: 100px;
        max-width: 400px;

        .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiOutlinedInput-adornedEnd {
            height: 35px;
            margin-bottom: 10px;

            button.MuiButtonBase-root.MuiIconButton-root {
                padding: 6px;
            }
        }
    }

    &:after {
        content: '';
        display: block;
        position: absolute;
        height: 8px;
        width: 100%;
        top: 0px;
        left: 0;
        transition: 0.3s;
        background-color: #3272a7;
        z-index: 1;
    }
}

// .tabpanel-custom {
//     width: 100%;
//     box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
//     margin-left: 25px;
//     padding: 10px;
//     position: relative;
//     padding-top: 20px;
//     overflow: auto;

//     &:after {
//         content: '';
//         display: block;
//         position: absolute;
//         height: 8px;
//         width: 100%;
//         top: 0px;
//         left: 0px;
//         transition: 0.3s;
//         background-color: #3272a7;
//         z-index: 1;
//     }
// }

// .app-table {
//     overflow: auto;
//     height: 280px;
// }
//

// checkmarks
.MuiTabs-root.MuiTabs-vertical.css-35zqo5-MuiTabs-root .MuiTabs-flexContainer.MuiTabs-flexContainerVertical.css-lfwcke-MuiTabs-flexContainer button {
    position: relative;
    padding-left: 0;

    &:not(:first-child):after {
        content: '';
        display: block;
        position: absolute;
        @include check(green);
        width: 24px;
        height: 24px;
        background-repeat: no-repeat;
        background-position: 0 0;
        background-size: 24px;
        right: 10px;
    }

    &:hover,
    &:focus {
        outline: none;
    }
}

// modal table
.modal-evaluation-sheet {
    text-align: center;

    .css-1jbbcbn-MuiDataGrid-columnHeaderTitle {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: break-spaces;
        font-weight: 500;
        line-height: 1.2;
        text-align: center;

        &:hover {
            cursor: pointer;
        }
    }
}

.MuiBox-root.css-11f99o8 {
    height: 100%;
}

// cards
.card-block {
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
    margin-bottom: 30px;

    .title {
        margin: 0;
        color: #727272;
    }

    .card-details {
        background-color: #f4f3f3;
        padding: 10px;

        div:last-child {
            align-self: end;
        }

        .description {
            margin: 0;
            color: #5b5c5c;
            font-weight: 500;
        }
    }

    .title-date,
    .discription-date {
        // align-items: self-end;
        font-size: 12px;
        text-align: right;
        margin: 0;
    }

    .card-comment {
        padding: 10px;

        p {
            margin: 0;
        }
    }
}
