.footer {
    background-color: $footer-bg;
    box-shadow: 0 1px 12px 0 rgba(0, 0, 0, 0.15), 0 3px 1px -2px rgba(0, 0, 0, 0.12);
    color: $footer-color;
    font-size: 13px;
    padding: 10px 0;
    position: relative;
    text-align: left;
    top: 0;
    z-index: 500;

    @media (max-width: map-get($grid-breakpoints, md)) {
        text-align: center;
    }

    svg {
        fill: #637581;
    }

    ul {
        list-style: none;
        padding: 0;

        li {
            display: inline-block;

            a {
                color: $footer-link-color;
                padding: 0 15px;
            }

            + li a {
                border-left: 1px solid #b2b2b2;
            }
        }
    }

    .footer-tagline {
        margin: 20px 0 0;
    }
}


.footer-logos {
    display: inline-block;
    width: 250px;

    img {
        vertical-align: middle;
    }

    @media (max-width: 1200px) {
        text-align: center;
        width: 100%;
    }
}

.logo-footer-hhs {
    height: 45px;
    width: 45px;
}

.logo-footer-nih {
    height: 28px;
    margin-left: 28px;
    width: 44px;
}

.logo-footer-usagov {
    height: 32px;
    margin-left: 20px;
}

.footer-text {
    display: flex;
    float: right;
    font-size: 14px;
    text-align: right;

    .footer-content,
    .application-details {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .req-links {
            a {
                padding: 0 10px;

                &:first-child {
                    padding-left: 0;
                }

                &:last-child {
                    padding-right: 0;
                }
            }
        }
    }

    .footer-content {
        border-right: solid 2px $gray-400;
        padding-right: 20px;
    }

    .application-details {
        padding-left: 20px;
    }

    @media (max-width: 1200px) {
        float: none;
        flex-direction: column;
        margin-top: 10px;
        text-align: center;

        .footer-content {
            border-right: none;

            .req-links {
                margin-bottom: 10px;
                margin-top: 10px;
            }
        }
    }

    .link-section {
        a {
            padding: 0 10px;
        }
    }
}

.footer-version {
    font-weight: 500;
}
