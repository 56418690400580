// Tables


.table {
    background-color: #fff;
    // padding: 20px 10px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25), 0 1px 1px rgba(0, 0, 0, 0.25);
}

.MuiDataGrid-root {
    border: 0 !important;
}
.MuiDataGrid-root .MuiDataGrid-colCellWrapper {
    background-color: #eeeeee;
    border-top: 2px solid #e7e6e2;
}


// .MuiDataGrid-cell {
//     background-color: red;
// }

.MuiDataGrid-colCell.MuiDataGrid-colCellSortable.MuiDataGrid-colCellSorted {
    background-color: #e7e6e2;
}

.MuiDataGrid-window {
    background-color: #fff;
}
.MuiSvgIcon-root.MuiDataGrid-sortIcon {
    fill: #499de1;
}

.MuiDataGrid-root .MuiDataGrid-main {
    .MuiDataGrid-row {

        max-height: none !important;
        min-height: auto !important;
        // max-width:200px !important;
        // min-width: 10em !important;
    }
    .MuiDataGrid-cell {
        max-height: none !important;
        overflow: auto;
        white-space: normal;
        line-height: 1.2em !important;
        // max-width: 300px !important;
        padding-top: 10px;
        padding-bottom: 10px;
        // min-width: 10em !important;
    }
}

// .MuiDataGrid-renderingZone {
//     width: 100% !important;
// }

.MuiDataGrid-columnHeaderTitle {
    font-family: "Roboto Condensed", "Roboto", sans-serif;
    font-weight: 700 !important;
}

.MuiDataGrid-columnHeaders {
    background-color: #F2F2F2;
    min-height: 50px !important;
    line-height: 50px !important;
}

.MuiDataGrid-columnHeader.MuiDataGrid-columnHeader--sortable.MuiDataGrid-columnHeader--sorted {
    background-color: #E7E6E2;
}

.MuiDataGrid-columnHeadersInner {
    border-top: 3px solid #E7E6E2;
}

.MuiDataGrid-iconSeparator {
    display: none !important;
}

.css-r11z79-MuiDataGrid-root .MuiDataGrid-cellContent {
    white-space: break-spaces;
}

// .css-1kwdphh-MuiDataGrid-virtualScrollerContent,.MuiDataGrid-virtualScroller.css-1w5m2wr-MuiDataGrid-virtualScroller,.MuiDataGrid-root.MuiDataGrid-autoHeight.MuiDataGrid-root--densityComfortable.css-r11z79-MuiDataGrid-root{
//     height: 100vh !important;
// }