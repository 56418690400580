.inner-tabs {
    .MuiBox-root.css-1gsv261 {
        border-bottom: 3px solid $blue;
    }

    .MuiTabs-flexContainer.css-heg063-MuiTabs-flexContainer {

        .MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
            border: 1px solid $blue;
            border-radius: 5px 5px 0 0;
            border-bottom: 0;
        }

        .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
            background-color: $blue;
            color: white;
        }

        :not(.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected) {
            color: $blue;
        }
    }

}


.tabs-results-table {
    padding: 20px;
    border: 1px solid $gray-light;
    border-top: none;

    .row {
        border-bottom: 1px solid $gray-light;
        padding: 10px 0;

        &:last-child {
            border-bottom: none;
        }
    }
    
}