.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiAccordion-root.MuiAccordion-rounded.Mui-expanded.MuiAccordion-gutters.css-1elwnq4-MuiPaper-root-MuiAccordion-root {
    margin: 0;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiAccordion-root.MuiAccordion-rounded.MuiAccordion-gutters.css-1elwnq4-MuiPaper-root-MuiAccordion-root {
    box-shadow: none;
    border: none;
}

.css-1elwnq4-MuiPaper-root-MuiAccordion-root:before {
    display: none;
}


.horizontal-line {
    width: 100%;
    margin: 0 20px;
    position: relative;
    // border: 1px solid yellow;

    &:before {
        position: absolute;
        background-color: rgba(0, 0, 0, 0.12);
        content: "";
        // width: 100%;
        left: 0;
        top: 50%;
        right: 0;
        height: 1px;
        opacity: 1;
    }
}

.accordion-data {
    .css-ahj2mt-MuiTypography-root {
        font-size: 1.2;
    }
    .title {
        font-weight: 500;
        margin: 0;
        font-size: 14px;
    }
    .description {
        color: $gray-600;
        font-size: 14px;
    }
}

.MuiAccordionSummary-content .MuiTypography-root.MuiTypography-body1 {
    white-space: nowrap;
    color: $navy;
    font-size: 1.1rem;
    font-weight: 500;
}