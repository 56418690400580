.login-page {

    .login-box {
        display: flex;
        justify-content: space-between;
        border: 1px solid #dedede;
        border-radius: 10px;
        margin-top: 30px;
        background-color: #fff;
        align-items: center;
        width: 100%;

        .student-account,
        .employee-account {
            width: 50%;
            padding: 20px 40px;
            font-size: 14px;
        }

        .employee-account {
            position: relative;
            &:before {
                content: '';
                position: absolute;
                left: 0;
                top: calc(60px - 40px);
                height: 200px;
                border-left: 2px solid $gray-300;
            }
        }
    }


    .homepage-jumbotron {
        min-height: none !important;

        .jumbotron-action {
            margin: 0;
            padding: 0;
            min-height: 0;
        }
    }
}

.warning-message {
    margin-top: 30px;
    background-color: #e3e3e3;
    padding: 20px;
    border-radius: 10px;
    font-size: 14px;
    strong {
        font-size: 18px;
    }
}

/*body {
    top: 100px;
    position: absolute;
}*/

.support-notice {
    color: #540808;
    display: inline-block;
    position: absolute;
    top: -100px;
    left: 0;
    width: 100%;
    min-height: 100px;
    height: 100px;
    font-size: 22px;
    background-color: #ff6d6d;
    z-index: 1000;
    padding: 8px 0px 8px 95px;
    display: table;
}

.support-notice:before {
    background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 512 512' style='enable-background:new 0 0 512 512;' xml:space='preserve'><g><g><path fill='%23fff' d='M501.362,383.95L320.497,51.474c-29.059-48.921-99.896-48.986-128.994,0L10.647,383.95 c-29.706,49.989,6.259,113.291,64.482,113.291h361.736C495.039,497.241,531.068,433.99,501.362,383.95z M256,437.241 c-16.538,0-30-13.462-30-30c0-16.538,13.462-30,30-30c16.538,0,30,13.462,30,30C286,423.779,272.538,437.241,256,437.241z M286,317.241c0,16.538-13.462,30-30,30c-16.538,0-30-13.462-30-30v-150c0-16.538,13.462-30,30-30c16.538,0,30,13.462,30,30 V317.241z'/></g></g></svg>");
    content: "";
    position: absolute;
    top: 25px;
    left: 30px;
    width: 50px;
    height: 50px;
    z-index: 2000;
    background-repeat: no-repeat;
    background-position: center 40%;
    background-color: #dd2222;
    background-size: 30px;
    border-radius: 50%;
    padding: 20px;
}

.support-notice-inner {
    display: table-cell;
    vertical-align: middle;
    line-height: 1.2em;
}