.jumbotron-header {
    background-color: #1c2f58;
    background-image: url('./../../Images/header.jpg');
    background-position: center;
    background-size: cover;
    margin-bottom: 0;
    padding: 0;
    position: relative;
    z-index: 20;

    svg {
        fill: $white;
    }

    header {
        display: flex;
        justify-content: space-between;
    }
    // &::before,
    // &::after {
    //   content: '';
    //   // height: 145px;
    //   height: 100%;
    //   max-width: 100vw;
    //   // mix-blend-mode: multiply;
    //   opacity: 0.3;
    //   position: absolute;
    //   top: 0;
    //   width: 600px;
    //   z-index: 1;
    //   @media (max-width: 600px) {
    //     max-width: 100%;
    //   }
    //   @media (max-width: 440px) {
    //     // height: 132px;
    //     opacity: 0.75;
    //   }
    // }
    // &::before {
    //   // background: rgb(35,35,94);
    //   // background: linear-gradient(90deg, rgba(35,35,94,0) 0%, rgba(56,21,78,1) 81%, rgba(45,17,62,1) 94%, rgba(106,20,128,1) 100%);
    //   background: linear-gradient(90deg, rgba(106, 20, 128, 1) 0%, rgba(45, 17, 62, 1) 6%, rgba(56, 21, 78, 1) 19%, rgba(35, 35, 94, 0) 100%);
    //   left: 0;
    // }
    // &::after {
    //   // background: rgb(20,142,160);
    //   // background: linear-gradient(90deg, rgba(20,142,160,1) 0%, rgba(17,69,109,1) 12%, rgba(14,51,108,1) 38%, rgba(35,35,94,0) 100%);
    //   background: linear-gradient(90deg, rgba(35, 35, 94, 0) 0%, rgba(14, 51, 108, 1) 62%, rgba(17, 69, 109, 1) 88%, rgba(20, 142, 160, 1) 100%);
    //   right: 0;
    // }
}

.jumbotron-home.jumbotron.jumbotron-header {
    backdrop-filter: blur(5px);
    background: transparent;
    box-shadow: $box-shadow-lg;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

.logo-nih {
    display: inline-block;
    // width: 384px;
    height: 60px;
    position: relative;
    z-index: 10;

    @media (max-width: map-get($grid-breakpoints, md)) {
        width: auto;
    }

    @media (max-width: 440px) {
        height: 40px;
        margin: 20px 0 20px 20px;
    }

    @media (max-width: map-get($grid-breakpoints, sm)) {
        // margin-left: 10px;
    }
}

.logo-nih-mark {
    height: 60px;

    @media (max-width: 440px) {
        height: 40px;
    }
}

.logo-nih-niaid-text {
    height: 60px;
    display: inline-block;

    @media (max-width: 1300px) {
        display: none;
    }
}

.application-title {
    color: #fff;
    display: table;
    float: right;
    font-size: 30px;
    margin-top: 4px;
    margin-right: 20px;
    min-height: 100px;
    position: relative;
    text-align: right;
    text-shadow: 0 0 21px #000, 0 2px 4px #000;
    z-index: 10;

    h1 {
        font-size: 30px;
        font-weight: 400;
        margin-top: 4px;
        margin-bottom: 0;
        vertical-align: middle;

        &:last-child {
            display: table-cell;
        }
    }

    @media (max-width: 1200px) {
        h1 {
            margin-top: 13px;
            font-size: 0;
            // position: relative;

            &::after {
                font-size: 30px;
                content: 'INRO';
                display: block;
            }
        }
    }

    @media (max-width: map-get($grid-breakpoints, sm)) {
        // margin-right: 10px;
        // position: absolute;
        // right: 0;
        // top: 0;
        // width: 100%;
        // h1 {
        //   font-size: 20px;
        //   width: 100%;
        // }
    }

    @media (max-width: 440px) {
        min-height: 80px;

        h1 {
            font-size: 18px;
        }
    }

    @media (max-width: 380px) {
        h1 {
            font-size: 14px;
        }
    }
}

.nih-logo-positioning {
    min-height: 100px;
    flex-direction: column;
    margin-left: 20px;
}

.top-header {
    display: flex;
    justify-content: space-between;
    padding: 24px 25px 24px 25px;

    @media (max-width: 568px) {
        flex-direction: column;

        .user-info {
            margin-top: 20px;
        }
    }

    .nih-details {
        display: flex;

        .application-details {
            color: #fff;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 0 16px;
            position: relative;
            z-index: 700;

            h1 {
                font-size: 26px;
                margin-bottom: 0;
                font-weight: 400;

                @media (max-width: 900px) {
                    font-size: 0;
                    visibility: hidden;

                    &::after {
                        content: 'INRO';
                        display: block;
                        font-size: 26px;
                        visibility: visible;
                    }
                }
            }

            .application-subtitle {
                font-size: 18px;
            }

            &::before {
                background: rgba(500, 500, 500, 0.5);
                content: '';
                display: block;
                height: 100%;
                left: 0;
                position: absolute;
                top: 0;
                width: 1px;
            }
        }


        .logo-nih {
            display: inline-block;
            padding: 0 14px 0 0;
            position: relative;
            z-index: 10;

            @media (max-width: map-get($grid-breakpoints, md)) {
                width: auto;
            }

            @media (max-width: 440px) {
                height: 40px;
                margin: 20px 0 20px 20px;
            }

            @media (max-width: map-get($grid-breakpoints, sm)) {
                // margin-left: 10px;
            }
        }
    }

    .application-user {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-right: 30px;
        z-index: 700;

        .app-user-centered {
            color: $white;
            display: flex;
            justify-content: space-around;

            .user-info {
                font-weight: 700;
                line-height: 1.25em;
                display: block;
                width: 100%;

                .user-name {
                    font-size: 14px;
                    display: block;
                }

                .user-title {
                    font-size: 13px;
                    font-weight: lighter;
                }
                // text-shadow: 0 0 21px rgba(0, 0, 0, 0.25), 0 2px 4px rgba(0, 0, 0, 0.25);
                @media (max-width: 440px) {
                    font-size: 12px;
                    line-height: 1.15em;
                }
            }

            .logout {
                border: 0;
                color: #fff;
                float: right;
                font-size: 14px;
                // background-color: $logout-color;
                height: 30px;
                margin-left: 18px;
                // margin-top: 3px;
                margin-right: 8px;
                padding: 4px 0;
                position: relative;
                text-shadow: none;

                @media (max-width: 440px) {
                    padding: 0;
                }
            }

            .btn-logout {
                background-color: $logout-color;
                border: 0;
                color: #fff;
                border-radius: 0 !important;
                display: inline-block;
                font-weight: normal;
                height: 30px;
                // background: none;
                line-height: 1.75em;
                padding: 1px 7px 2px;
                position: relative;
                text-align: center;
                top: -4px;

                &::before {
                    border-bottom: 30px solid $logout-color;
                    border-left: 8px solid transparent;
                    border-right: 0 solid $logout-color;
                    content: '';
                    position: absolute;
                    right: 100%;
                    top: 0;
                }

                &::after {
                    border-left: 0 solid $logout-color;
                    border-right: 8px solid transparent;
                    border-top: 30px solid $logout-color;
                    content: '';
                    left: 100%;
                    position: absolute;
                    top: 0;
                }

                &:hover,
                &:focus {
                    background-color: $nav-link;
                    color: #fff;
                    text-decoration: none;

                    &::before {
                        border-bottom-color: $nav-link;
                        border-right-color: $nav-link;
                    }

                    &::after {
                        border-left-color: $nav-link;
                        border-top-color: $nav-link;
                    }
                }
            }

            &.not-logged-in {
                .user-info {
                    padding: 4px 0;
                }

                .logout {
                    padding: 0;
                }
            }
        }
    }

    .user-info {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .user-vert-container {
            color: #fff;
            display: flex;
        }
    }
}

.user-info {
    font-size: 14px;
    font-weight: 700;
    line-height: 1.25em;
    text-shadow: 0 0 21px #000, 0 2px 4px #000;
    display: flex;
    justify-content: flex-end;

    @media (max-width: 440px) {
        font-size: 12px;
        line-height: 1.15em;
    }


    .user-title {
        font-weight: normal;
    }

    .logout {
        border: 0;
        color: #fff;
        float: right;
        font-size: 14px;
        // background-color: $logout-color;
        height: 30px;
        margin-left: 18px;
        // margin-top: 3px;
        margin-right: 8px;
        padding: 4px 0;
        position: relative;
        text-shadow: none;

        @media (max-width: 440px) {
            padding: 0;
        }
    }

    .btn-logout {
        background-color: $logout-color;
        border: 0;
        color: #fff;
        display: inline-block;
        font-weight: normal;
        height: 30px;
        // background: none;
        line-height: 1.9em;
        padding: 1px 7px 2px;
        position: relative;
        text-align: center;
        top: -4px;

        &::before {
            border-bottom: 30px solid $logout-color;
            border-left: 8px solid transparent;
            border-right: 0 solid $logout-color;
            content: '';
            position: absolute;
            right: 100%;
            top: 0;
        }

        &::after {
            border-left: 0 solid $logout-color;
            border-right: 8px solid transparent;
            border-top: 30px solid $logout-color;
            content: '';
            left: 100%;
            position: absolute;
            top: 0;
        }

        &:hover,
        &:focus {
            background-color: $nav-link;
            color: #fff;
            text-decoration: none;

            &::before {
                border-bottom-color: $nav-link;
                border-right-color: $nav-link;
            }

            &::after {
                border-left-color: $nav-link;
                border-top-color: $nav-link;
            }
        }
    }

    .header-select {
        height: 30px;
        position: relative;
        top: 8px;
        width: 100%;

        img,
        svg {
            height: 14px;
            position: absolute;
            right: 7px;
            top: 10px;
        }

        .MuiFormControl-root {
            width: 100%;
        }

        .MuiInputBase-input {
            color: white;

            option {
                color: black;
            }
        }

        label + .MuiInput-formControl {
            margin-top: 0;
        }

        .MuiSelect-root {
            background-color: $logout-color;
            min-width: 55px;
            font-size: 15px;
            border-radius: 5px;
            padding-left: 5px;
        }
    }
}

.navbar {
    background-color: $banner-color;
    // padding-bottom: 0;
    // padding-left: 20px;
    // padding-top: 6px;
    padding: .5rem 1rem 0;
    text-shadow: none;
    // font-family: $font-family-base;
    z-index: 10;

    &:after {
        content: "";
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        height: 0 !important;
        background: transparent;
        background: linear-gradient(0deg, rgba(29,197,250,0) 0%, rgba(29,197,250,0.04) 25%, rgba(29,197,250,0.1) 50%, rgba(29,197,250,0.25) 100%);
        opacity: 0.15;
    }

    @media (max-width: map-get($grid-breakpoints, sm)) {
        padding-left: 10px;
        padding-right: 10px;
    }
}

.navbar-expand-md,
.navbar-expand-lg {
    .navbar-nav {
        .nav-link {
            border-radius: 5px 5px 0 0;
            color: #fff;
            font-size: 18px;
            font-weight: 500;
            margin-right: 5px;
            padding: 3px $nav-link-padding 9px;
            text-transform: uppercase;

            @media (max-width: map-get($grid-breakpoints, md)) {
                border-radius: 0;
            }

            @media (min-width: map-get($grid-breakpoints, md)) {
                padding-right: 1rem;
                padding-left: 1rem;
            }
        }

        .dropdown-btn {
            border: 0;
        }

        .nav-item {
            &:not(.active) {
                .nav-link {
                    &:hover,
                    &:focus {
                        background-color: #1a637d;
                        color: #fff;

                        &:after {
                            @include arrow-right(#fff);
                        }
                    }
                }
            }

            .active {
                background-color: #fff;
                color: $banner-color;
            }

            .dropdown-menu {
                .dropdown-item:active {
                    a {
                        color: #fff;
                        text-decoration: none;
                    }
                }
            }
        }
    }
}

a.dropdown-link {
    color: inherit;
    text-decoration: none;
}

.navbar-dark .nav-item.active .nav-link {
    background-color: $bg-color;
    color: $nav-link;
}

.navbar-dark {
    .toggler-positioning {
        @media (max-width: 768px) {
            display: flex;
            justify-content: flex-end;
            padding-left: 25px;
            padding-right: 25px;
            width: 100%;

            .navbar-toggler {
                color: #fff;
                margin-bottom: 6px;
                padding: 0.25rem 0.5rem;
            }
        }
    }

    .navbar-toggler {
        margin-bottom: 8px;
    }

    .navbar-toggler-icon {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(255, 255, 255, 2)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
    }

    .navbar-nav .nav-link {
        color: #fff;
        background-color: transparent;
    }
}

.jumbotron-lg {
    position: relative;
    // background: url('./../../Images/sample-img/header-lg.png');
    background-size: 100%;

    .site-abbrev {
        font-size: 26px;
        font-weight: bold;
        margin-top: -10px;
    }

    &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 60px;
        background: transparent;
        background: linear-gradient(0deg, rgba(29,197,250,0.25) 0%, rgba(29,197,250,0.1) 50%, rgba(29,197,250,0.04) 75%, rgba(29,197,250,0) 100%);
    }
}

.register-account {
    img {
        margin-left: 5px;
    }
}

.navbar-nav .nav-item:not(:first-child) a.nav-link,
.dropdown-btn {
    position: relative;
    padding-right: 30px;

    &:after {
        content: '';
        position: absolute;
        // background: url(../../Images/icons/icon-arrow-down-white.svg);
        @include arrow-right(#fff);
        transform: rotate(90deg);
        background-size: cover;
        background-position: center;
        display: block;
        height: 25px;
        width: 25px;
        right: 4px;
        top: 4px;
    }

    &.active:after {
        // fill: #123b56;
        @include arrow-right(#123b56);
    }
}

button.nav-link,
button.nav-link.dropdown-btn.active {
    padding-right: 26px !important;
    outline: none;
}

.navmenu-dropdown {
    ul.MuiList-root.MuiList-padding.MuiMenu-list.css-6hp17o-MuiList-root-MuiMenu-list {
        padding: 0;
    }
}

.help-buttons {
    display: flex;
    justify-content: space-between;
    margin: 0 10px;
}
