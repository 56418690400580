/*
content.scss

core setup and material ui overrides
1. content and sidebar main-content arrangement
2. buttons
3. forms and tables


specifics
1. homepage jumbotron
2. section heading
3. internal login
*/


//////////////////////////////
// MATERIAL OVERRIDES
//////////////////////////////

// 1. content and sidebar main-content arrangement
.content {
    min-height: calc(100vh - 150px);
    position: relative;
}

.main-content {
    background: $bg-color;
    min-height: 100vh;
    padding: 30px;
    position: relative;
    transition: 0.3s;

    &.centered-content {
        margin: auto;
        max-width: 1800px;
    }
}

.sidebar {
    ~ .main-content {
        left: $sidebar-width;
        width: calc(100% - #{$sidebar-width});
        position: relative;

        @media (max-width: map-get($grid-breakpoints, lg)) {
            // min-width: 100%;
            left: 0;
            width: 100%;
        }

        &.centered-content {
            margin: unset;

            @media (max-width: 1300px) {
                left: 0;
                width: 100%;
            }
        }
    }

    &.closed ~ .main-content {
        left: 0;
        width: 100%;

        &.centered-content {
            left: 0;
            margin: auto;
            max-width: 1600px;
        }
    }
}

// 2. buttons
button {
    border-radius: 10px;

    + button,
    + .MuiButtonBase-root {
        margin-left: 10px;
    }
}

.MuiButtonBase-root {

    &:focus,
    &:hover {
        box-shadow: none;
    }

    &.warning-btn,
    &.light-btn-primary,
    &.btn-primary,
    &.btn-success,
    &.btn-white,
    &.transparent-btn,
    &.btn-outline-primary {
        // box-shadow: none;
        // font-size: 13px;
        // font-weight: 500;
        // margin: 0 2.5px;
        // padding: 3px 16px;

        img,
        svg {
            height: 14px;
            margin-right: 8px;
        }
    }

    &.view-solicitation-btn {
        background-color: #e9f1f6;
        border: 0;
        color: #1f558a;
        font-weight: 500;
        font-size: 13px;
        min-width: 150px;
        padding-right: 25px;
        padding-left: 10px;
        // float: right;
        position: relative;

        &::after {
            background: url('./../../Images/icons/icon-arrow-right-blue.svg');
            background-size: cover;
            background-position: center;
            content: '';
            display: block;
            height: 10px;
            position: absolute;
            right: 8px;
            top: 10px;
            width: 10px;
        }

        &:hover,
        &:focus {
            background: darken(#e9f1f6, 10%);
            box-shadow: none;
            transition: .3s;
        }
    }

    &.right-align-btn {
        float: right;
    }

    &.delete-red-btn {
        color: #6f5048;
        background: #f7ebe8;
        padding-right: 40px;

        &::after {
            content: '';
            display: block;
            position: absolute;
            right: 12px;
            top: 8px;
            background: url('./../../Images/icons/trash-brown.svg');
            height: 16px;
            width: 16px;
        }

        &:hover,
        &:focus {
            background: darken(#f7ebe8, 10%);
            transition: .3s;
            box-shadow: none;
            // margin-right: 10px;
        }
    }

    &.transparent-btn {
        border: solid 1px transparent;
        color: #000;
        background: transparent;

        &:hover,
        &:focus {
            border-color: #e2e2e2;
            transition: .3s;
        }
    }

    &.light-btn-primary {
        background: #eaf1f7;
        box-shadow: none;
        color: $darkblue;

        &:hover,
        &:focus {
            background: darken(#eaf1f7, 10%);
            box-shadow: none;
            transition: .3s;
        }

        .submit-proposal-button-label {
            margin-bottom: 0;
        }
    }

    &.primary-btn,
    &.btn-primary {
        background: $darkblue;
        color: white;

        &:hover,
        &:focus {
            background: darken($darkblue, 10%);
            transition: .3s;
        }
    }

    &.med-primary-btn,
    &.med-btn-primary,
    &.btn-med-primary {
        background: $darkmedblue;
        color: white;

        &:hover,
        &:focus {
            background: darken($darkmedblue, 10%);
            transition: .3s;
        }
    }

    &.btn-secondary {
        background: #757575;
        color: white;

        &:hover,
        &:focus {
            background: darken(#757575, 10%);
            transition: .3s;
        }
    }

    &.btn-success {
        background: #348d6c;
        color: #fff;
    }

    &.green-addition-button {
        color: white;
        display: inline-block;
        background: #3c967e;
        border: 0;
        border-radius: 25px;
        margin-top: 8px;
        padding: 6px 15px 6px 35px;
        white-space: nowrap;
        -webkit-user-select: none;
        user-select: none;
        cursor: pointer;
        font-size: 14px;
        transition: .3s;

        &::before {
            background-image: url('./../../Images/icons/plus-white.svg');
            content: '';
            display: block;
            height: 14px;
            left: 13px;
            position: absolute;
            top: 7px;
            width: 14px;
        }

        &:hover,
        &:focus {
            color: white;
            box-shadow: none;
            background: darken(#3c967e, 10%);
            transition: .3s;
        }
    }

    &.add-btn {
        padding-left: 40px;
        padding-right: 15px;

        &::before {
            background-image: url('./../../Images/icons/plus-white.svg');
            content: '';
            display: block;
            height: 14px;
            left: 15px;
            position: absolute;
            top: 10px;
            width: 14px;
        }
    }

    &.cancel-btn {
        padding-left: 30px;
        padding-right: 10px;
        min-width: 90px;

        &::before {
            background-image: url('./../../Images/icons/icon-no-gray.svg');
            content: '';
            display: block;
            height: 14px;
            left: 10px;
            position: absolute;
            top: 8px;
            width: 14px;
        }
    }

    &.delete-white-btn {
        padding-left: 30px;
        padding-right: 10px;
        min-width: 90px;

        &::before {
            content: '';
            display: block;
            position: absolute;
            left: 8px;
            top: 7px;
            background: url('./../../Images/icons/icon-trash-gray.svg');
            height: 16px;
            width: 16px;
        }
    }

    &.edit-white-btn {
        padding-left: 30px;
        padding-right: 10px;
        min-width: 90px;

        &::before {
            content: '';
            display: block;
            position: absolute;
            left: 15px;
            top: 7px;
            background: url('./../../Images/icons/icon-edit-gray.svg');
            height: 16px;
            width: 16px;
        }
    }

    &.btn-alert {
        background: $red;
        color: white;
    }

    &.btn-med-blue-chevron-j {
        $color1: #007daa;
        $color2: #068bb4;

        background: $color1;
        position: relative;
        color: #fff;
        min-width: 350px;
        transition: none;
        border-radius: 4px;
        overflow: hidden;

        .chevron-text {
            z-index: 100;
        }

        &::before {
            content: '';
            display: block;
            width: 90px;
            left: 0;
            position: absolute;
            height: 100%;
            background-color: $color2;
            background-image: linear-gradient(-60deg, $color1 40px, transparent 40px), linear-gradient(-120deg, $color1 40px, transparent 40px);
        }

        &::after {
            content: '';
            display: block;
            width: 90px;
            right: 0;
            position: absolute;
            height: 100%;
            background-color: $color1;
            background-image: linear-gradient(-60deg, $color2 50px, transparent 50px), linear-gradient(-120deg, $color2 50px, transparent 50px);
        }

        &:hover,
        &:focus {
            $color1dark: darken($color1, 5%);
            $color2dark: darken($color2, 5%);

            background: $color1dark;

            &::before {
                background-color: $color2dark;
                background-image: linear-gradient(-60deg, $color1dark 40px, transparent 40px), linear-gradient(-120deg, $color1dark 40px, transparent 40px);
            }

            &::after {
                background-color: $color1dark;
                background-image: linear-gradient(-60deg, $color2dark 50px, transparent 50px), linear-gradient(-120deg, $color2dark 50px, transparent 50px);
            }
        }
    }

    &.btn-med-blue-chevron {
        $color1: #03729b;
        $color2: #048bbc;
        $overlay-color: rgba(255, 255, 255, 0.08);

        background: linear-gradient(90deg, $color1 0%, $color2 100%);
        position: relative;
        padding: 0.5em 4em;
        color: #fff;
        transition: none;
        border-radius: 4px;
        overflow: hidden;

        &::before,
        &::after {
            content: '';
            display: block;
            position: absolute;
            height: 100%;
        }

        &::before {
            width: 6em;
            left: 0;
            background: conic-gradient(from -30deg, transparent 0deg, transparent 240deg, $overlay-color 240deg, $overlay-color 360deg);
        }

        &::after {
            width: 4.5em;
            right: 0;
            background: conic-gradient(from -30deg, $overlay-color 0deg, $overlay-color 240deg, transparent 240deg, transparent 360deg);
        }

        &:hover,
        &:focus {
            $color1dark: darken($color1, 5%);
            $color2dark: darken($color2, 5%);

            background: linear-gradient(90deg, $color1dark 0%, $color2dark 100%);
        }
    }

    &.btn-large-box {
        // border: 1px solid black;
        background-color: #fff;
        color: #000;
        text-align: center;
        text-transform: none;
        font-weight: normal;
        // width: 160px;
        width: 14.2857142857%;
        padding: 45px 0px 10px 0px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25), 0 1px 1px rgba(0, 0, 0, 0.25);
        margin: 0 20px 10px 0;

        .btn-text {
            z-index: 10;
        }

        .btn-count {
            display: block;
            position: absolute;
            font-size: 24px;
            font-weight: 500;
            top: 10px;
            z-index: 10;
        }

        &:hover,
        &:focus,
        &.active {
            color: #fff;

            &:after {
                border-radius: 4px;
                height: 100%;
            }
        }

        &:before {
            content: '';
            display: block;
            position: absolute;
            font-size: 24px;
            font-weight: 500;
            top: 10px;
            z-index: 10;
        }

        &:after {
            content: '';
            display: block;
            position: absolute;
            height: 5px;
            width: 100%;
            top: 0px;
            // background-color: #bbb;
            border-radius: 4px 4px 0px 0px;
            transition: 0.3s;
            z-index: 1;
        }

        &.btn-all {
            &:before {
                content: ''
            }

            &:after {
                background-color: $color-box-blue;
            }
        }

        &.btn-saved {
            &:before {
                content: '';
            }

            &:after {
                background-color: $color-box-brightGreen;
            }
        }

        &.btn-pendingforeligible {
            &:before {
                content: '';
            }

            &:after {
                background-color: $color-box-lightPurple;
            }
        }

        &.btn-stared {
            &:before {
                content: '';
            }

            &:after {
                background-color: $color-box-yellow;
            }
        }

        &.btn-eligibleforreview {
            &:before {
                content: '';
            }

            &:after {
                background-color: $color-box-purple;
            }
        }

        &.btn-reviewinprogress {
            &:before {
                content: '';
            }

            &:after {
                background-color: $color-box-Lightorange;
            }
        }

        &.btn-reviewcompleted {
            &:before {
                content: '';
            }

            &:after {
                background-color: $color-box-orange;
            }
        }

        &.btn-offered {
            &:before {
                content: '';
            }

            &:after {
                background-color: $color-box-maroon;
            }
        }

        &.btn-notoffered {
            &:before {
                content: '';
            }

            &:after {
                background-color: $color-box-lightPink;
            }
        }

        &.btn-offer-accepted {
            &:before {
                content: '';
            }

            &:after {
                background-color: $color-box-green;
            }
        }

        &.btn-offer-declined {
            &:before {
                content: '';
            }

            &:after {
                background-color: $color-box-lightGreen;
            }
        }

        &.btn-alternate {
            &:before {
                content: '';
            }

            &:after {
                background-color: $color-box-gray;
            }
        }

        &.btn-starred {
            &:before {
                content: '';
            }

            &:after {
                background-color: $color-box-yellow;
            }
        }

        &.btn-joinedniaid {
            &:before {
                content: '';
            }

            &:after {
                background-color: $color-box-skyBlue;
            }
        }

        &.btn-withdrawn {
            &:before {
                content: '';
            }

            &:after {
                background-color: $color-box-brown;
            }
        }

        .MuiButton-label {
            z-index: 10;
        }

        @media (max-width: 950px) {
            font-size: 12px;
            padding: 60px 5px 7px 5px;

            &:before {
                width: 50px;
                height: 50px;
            }
        }

        @media (max-width: 790px) {
            font-size: 12px;
            font-family: "Roboto Condensed", sans-serif;
            font-weight: 600;
            padding: 60px 5px 7px 5px;
            // + .btn-large-icon {
            //   margin-left: 8px;
            // }

            &:before {
                width: 50px;
                height: 50px;
            }
        }
    }
}

.MuiButton-root {
    &.btn-white {
        background: $white;
        border: solid 2px $gray-600;
        box-sizing: border-box;
        color: $gray-600;

        svg,
        img {
            height: 12px;
            margin-right: 6px;
            position: relative;
            stroke: $gray-600;
            top: 0;
        }

        &:hover,
        &:focus {
            background: darken($white, 10%);
            transition: 0.3s;
        }
    }

    &.btn-download {
        background: $purple;
        color: $white;
        padding: 4px 16px;
        transition: 0.3s;

        img,
        svg {
            height: 15px;
            margin-right: 8px;
        }

        &:hover,
        &:focus {
            background: darken($purple, 10%);
            transition: 0.3s;
        }
    }

    &.btn-download-white {
        background: #fff;
        border-radius: 25px;
        box-shadow: 0 1px 12px 0 rgba(0, 0, 0, 0.15), 2px 0 1px -2px rgba(0, 0, 0, 0.12);
        float: none;
        margin-left: 8px;
        padding-left: 16px;
        padding-right: 35px;
        color: #3272a7;
        transition: 0.3s;

        &::after {
            content: '';
            display: block;
            position: absolute;
            right: 12px;
            top: 8px;
            background: url('./../../Images/icons/download-blue.svg');
            height: 16px;
            width: 16px;
        }
    }

    &.btn-download-green {
        border-radius: 4px;
        background-color: #319479;
        box-shadow: 0 1px 12px 0 rgba(0, 0, 0, 0.15), 2px 0 1px -2px rgba(0, 0, 0, 0.12);
        float: none;
        padding-left: 16px;
        padding-left: 35px;
        padding-right: 10px;
        color: #fff;
        transition: 0.3s;
        text-transform: none;

        &::before {
            content: '';
            display: block;
            position: absolute;
            left: 12px;
            top: 10px;
            background: url('./../../Images/icons/download-blue.svg');
            filter: invert(150%) sepia(100%) saturate(26%) hue-rotate(4deg) brightness(145%) contrast(247%);
            height: 16px;
            width: 16px;
        }

        &:hover,
        &:focus {
            background: darken($green, 10%);
            transition: 0.3s;
        }
    }
}

.gradient-button {
    border: 0;
    border-radius: 3px;
    box-shadow: $shadow;
    color: $white;
    font-weight: 500;
    padding: 5px 10px;
    transition: 0.3s;

    .plus {
        border: solid 1.5px $white;
        border-radius: 100%;
        height: 22px;
        margin-right: 8px;
        padding: 5px;
        position: relative;
        width: 22px;

        .plus-icon {
            // background-image: url('/images/plus-white.svg');
            display: inline-block;
            height: 12px;
            left: 3.25px;
            position: absolute;
            top: 3.32px;
            width: 12px;
        }
    }

    &:hover,
    &:focus {
        background: darken($white, 5%);
        transition: 0.3s;
    }
}

// 3. Forms and Tables
body,
form,
table {
    .form-row {
        margin-bottom: 15px;
    }

    .MuiOutlinedInput-notchedOutline {
        display: none;
    }

    .MuiFormControl-root,
    .MuiTable-root {
        width: 100%;

        &.white-input {

            input,
            textarea {
                background: #fff;
            }
        }

        label,
        .MuiFormLabel-root {
            color: $gray-600;
            font-size: 14px;
            font-weight: 500;
        }

        small {
            font-style: italic;
            margin-left: 5px;
        }

        .Mui-checked {
            svg.MuiSvgIcon-root {
                fill: $blue;
            }
        }

        .MuiCheckbox-root {
            padding: 0;
            width: inherit;
            margin-left: 12px;
            margin-right: 5px;

            .MuiSvgIcon-root {
                margin-left: 0;
            }
        }

        textarea {
            outline: none;
            background: $bg-color;
            box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.24), 0px 2px 2px 0px rgba(0, 0, 0, 0.15);
            padding: 10px;
            border-radius: 5px;
            border: 0;
        }

        .MuiInputBase-root {
            appearance: none;
            background: $bg-color;
            background-image: none;
            border: 0;
            box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.24), 0px 2px 2px 0px rgba(0, 0, 0, 0.15);
            // , 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
            border-radius: 5px;
            font-size: 16px;
            font-weight: 400;
            margin-right: 10px;
            margin-top: 5px;
            // text-overflow: '';
            width: 100%;

            &.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"] .MuiAutocomplete-input {
                padding: 0;
            }

            input,
            select {
                background: $bg-color;
                border: 0;
                border-radius: 5px;
                font-size: 16px;
                font-weight: 400;
                height: 30px;
                padding: 0 10px;
                width: 100%;
            }

            .MuiInputBase-input.MuiSelect-select {
                padding-bottom: 5px;
                padding-left: 10px;
            }

            .MuiChip-root.multiselect-chip {
                height: 20px;
            }

            select {
                display: block;
                overflow: hidden !important;
                text-overflow: ellipsis;
                margin-right: -15px;

                & + .select-arrow-container {
                    background: $bg-color;
                    height: 17px;
                    width: 17px;
                    pointer-events: none;
                    position: relative;
                    right: 8px;
                }
            }

            &[readonly],
            &[disabled] {
                background: transparent;
                color: $black;
                opacity: 1;
                padding-left: 0;
                text-indent: 0;

                img {
                    display: none;
                }
            }
        }

        .MuiIconButton-root {
            background: transparent;
            border: 0;
            position: relative;

            .MuiIconButton-label .MuiSvgIcon-root {
                height: 25px;
                width: 25px;
            }
        }

        &.chip-parent {
            .MuiInputBase-root.Mui-error {
                padding-bottom: 0;
                position: relative;

                &::after {
                    background: $red;
                    border-bottom-left-radius: 2.5px;
                    border-bottom-right-radius: 2.5px;
                    border-width: 2px;
                    bottom: 0;
                    content: '';
                    display: block;
                    height: 2px;
                    margin: auto;
                    position: absolute;
                    width: 100%;
                    z-index: 20;
                }
            }

            p {
                padding-top: 5px;
                position: relative;
            }
        }

        &.calendar-parent {
            margin-bottom: 0 !important;

            input {
                padding-right: 32px;
                display: block;
                overflow: hidden !important;
                text-overflow: ellipsis;
            }

            &::before {
                display: none;
            }

            .MuiInputAdornment-root {
                position: absolute;
                top: 15px;
                right: -5px;

                button:focus {
                    outline: none;
                }

                svg {
                    fill: $gray-med;
                }
            }

            .MuiIconButton-root .MuiIconButton-label {

                svg.MuiSvgIcon-root,
                img {
                    fill: #757575;
                    height: 21px;
                    width: 22px;
                }
            }
        }

        &.money-parent {
            .MuiInputAdornment-positionStart {
                position: absolute;
                margin-right: 0;
                left: 8px;
                top: 15px;
            }

            input {
                padding-left: 18px;
            }
        }

        .MuiFormHelperText-root.Mui-error {
            color: $red;
        }

        .MuiInput-underline.Mui-error {
            padding-bottom: 0;

            &::after {
                border-bottom-left-radius: 2.5px;
                border-bottom-right-radius: 2.5px;
                border-color: $red;
                border-width: 2px;
                position: absolute;
                top: 0;
                width: 100%;
            }
        }

        .MuiInputLabel-formControl {
            position: relative;
        }

        .split-input {
            margin-top: 15.5px;
        }

        .MuiInputLabel-shrink,
        .MuiInputLabel-root {
            transform: none;
        }

        .MuiInputBase-multiline {
            line-height: 2rem;
            padding: 0;

            textarea {
                line-height: 1.4rem;
                padding: 4px 10px;
                resize: vertical;
            }
        }

        &.readonly-input {
            img {
                display: none;
            }
        }

        .select-down-arrow {
            height: 16px;
            pointer-events: none;
            // position: absolute;
            // right: 8px;
        }

        &.container-sortby {
            margin-bottom: 45px !important;
            /*img {
        height: 16px;
        position: relative;
        right: 8px;
      }*/
        }

        &.readonly-input {
            img {
                display: none;
            }
        }
    }

    .MuiFormHelperText-root {
        margin-left: 0;
        margin-right: 0;
    }

    .split-input {
        display: flex;
        position: relative;

        .MuiInputBase-root {
            flex: 1 1 0;
        }

        .split-button {
            background: $white;
            border: 0;
            border-radius: 100%;
            box-shadow: $shadow;
            height: 24px;
            margin: auto;
            margin-left: 8px;
            position: relative;
            top: 2px;
            transition: 0.3s;
            width: 24px;

            .plus-icon {
                // background-image: url('/images/plus.svg');
                display: inline-block;
                height: 12px;
                left: 0;
                position: relative;
                top: 1px;
                width: 12px;
            }

            &:hover,
            &:focus {
                // background: darken($$white, 5%);
                box-shadow: $shadow-strong;
                transition: 0.3s;
            }
        }
    }

    .input-disabled,
    .select-disabled {

        .split-button,
        .MuiFormHelperText-root {
            display: none;
        }
    }

    .additional-section {
        border: solid 2px transparent;
        border-radius: 10px;
        box-shadow: $shadow;
        margin-bottom: 20px;
        margin-left: -5px;
        margin-right: -5px;
        padding: 20px 10px 5px;
        padding-bottom: 40px;

        .btn-secondary {
            margin-right: 5px;
            position: absolute;
            right: 0;
        }

        &.error-thrown {
            border: solid 2px $red;
            // &::after {
            //   color: $red;
            //   content: 'Please fill out the above section.';
            // }
        }

        @media (max-width: map-get($grid-breakpoints, md)) {
            .btn-secondary {
                bottom: unset;
                top: -15px;
            }
        }
    }

    @media (max-width: map-get($grid-breakpoints, lg)) {
        .form-row {
            margin-bottom: unset;
        }

        .MuiFormControl-root {
            padding-bottom: 20px;
        }
    }

    input[readonly] {
        background: $white;
    }

    .manageVendor-searchbar.MuiFormControl-root.shrunk-searchbar {
        width: 500px;
    }
}

.MuiSelect-root .MuiChip-root {
    background-color: #d6e5ef;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.24), 0px 2px 2px 0px rgba(0, 0, 0, 0.15);
    margin-right: 2px;
}

.search-box {
    min-width: 400px;

    .MuiFormControl-root.MuiTextField-root {
        width: 100%;
    }

    .MuiFormControl-root .MuiInputBase-root {
        &,
        input {
            background-color: #fff;
        }
    }
}

// 4.a page tabs
.internal-page-tabs {
    .nav-tabs {
        border-bottom: none;

        a {
            border: 0;
            color: #000;
            font-size: 15px;
            font-weight: 500;
            padding: 0.5rem 1.25rem;
            text-transform: uppercase;
            transition: .3s;

            .badge-pill {
                margin-left: 7px;
                padding: .45em .6em;
                border-radius: 15px;
                position: relative;
                top: -3px;

                &.badge-primary {
                    background-color: #20558A;
                }

                &.badge-danger {
                    background-color: #DC004E;
                }
            }

            .count-icon {
                background: #d7d7d7;
                border-radius: 11px;
                font-size: 12px;
                margin-left: 8px;
                padding: 4px 8px;
                position: relative;
                top: -1px;
            }

            &.active,
            &.active:hover {
                background: $darkmedblue;
                color: #fff;

                .count-icon {
                    background: $darkblue;
                }
            }

            &:hover {
                background: darken($bg-color, 10%);
                transition: .3s;
            }
        }
    }

    .tab-content {
        border-top: 4px solid $darkmedblue;
        position: relative;

        .tile-section {
            &::before {
                display: none;
            }

            .section-heading {
                padding-bottom: 25px;
                // padding-left: 15px;
            }
        }

        .accordion-flex-heading {
            display: flex;
            justify-content: space-between;
        }

        .above-tab-actions {
            position: relative;
            top: unset;
            right: unset;

            button {
                font-size: 13px;
                margin-right: 5px;
                padding: 3px 15px;
                text-transform: capitalize;

                &:last-child {
                    margin-right: 0;
                }
            }

            img,
            svg {
                height: 14px;
                margin-right: 10px;
                position: relative;
                top: 0;
            }
        }
    }
}


//////////////////////////////
// THEME SPECIFICS
//////////////////////////////

// 1. homepage jumbotron
.homepage-jumbotron {
    position: relative;
    // padding: 40px;
    // min-height: calc(100vh - 201px);

    @media (max-width: 993px) {
        height: auto;
    }

    .jumbotron-notice,
    .jumbotron-notice--notice {
        border-radius: 4px;
        max-width: 440px;
    }

    .primary-jumbo-text {
        font-size: 34px;
        font-weight: 300;
        color: #fff;
    }

    .jumbotron-lg-content {
        padding: 40px 0 0 0;

        .jumbotron-text {
            @media (max-width: 992px) {
                padding-right: 0;

                .jumbotron-notice {
                    max-width: 100%;
                }
            }

            .jumbotron-notice {
                background-color: #fff;
                color: #333;
                font-size: 12px;
                padding-top: 15px;
                padding-bottom: 15px;

                .jumbotron-notice--message {
                    padding: 10px;
                    font-size: 18px;

                    > p:last-child {
                        margin-bottom: 0;
                    }
                }

                .jumbotron-notice--notice {
                    background-color: #e9f1f6;
                    padding: 15px;
                }
            }
        }

        .jumbotron-actions {
            display: flex;

            .jumbotron-action {
                .btn-guide {
                    &::before {
                        content: '';
                        display: block;
                        height: 100%;
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 5px;
                        background: #05cda0;
                        border-top-left-radius: 5px;
                        border-bottom-left-radius: 5px;
                    }

                    &::after {
                        background: url('./../../Images/icons/lock.svg');
                        background-size: cover;
                        background-position: center;
                        content: '';
                        display: block;
                        height: 40px;
                        position: absolute;
                        right: 8px;
                        top: 15px;
                        width: 40px;
                    }
                }

                #ecps-login-btn {
                    margin-bottom: 50px;

                    &::before {
                        background: #05cda0;
                    }

                    .chevron-right {
                        background: linear-gradient(120deg, #038a6b, #04bd93)
                    }
                }

                #nih-login-btn {
                    &:before {
                        background: #0381ae;
                    }

                    .chevron-right {
                        background: linear-gradient(120deg, #0293c7, #0381ae);
                    }
                }

                #erds-login-btn {
                    &:before {
                        background: #0381ae;
                    }

                    .chevron-right {
                        background: linear-gradient(120deg, #0293c7, #0381ae);
                    }
                }

                .btn-guide {
                    // min-width: 135px;
                    // min-width: 265px;
                    min-width: 300px;
                    width: 100%;
                    background: #fff;
                    color: #333;
                    font-size: 22px;
                    position: relative;
                    padding: 0;
                    border-radius: 5px;
                    text-transform: unset;
                    font-weight: 400;
                    border: none;
                    padding-left: 20px;

                    .chevron-right {
                        min-width: 90px;
                    }

                    &:after {
                        height: 50px;
                        top: 20px;
                        width: 50px;
                    }
                }
            }
        }
    }
}

// 2. section heading
.section-heading {
    color: $navy;
    font-size: 24px;
    font-weight: 400;
    padding-bottom: 10px;

    .header-actions {
        float: right;

        .MuiButtonBase-root {
            padding: 3px 16px;
        }

        .white-btn {
            background: $white;
            border-radius: 25px;
            box-shadow: 0 1px 12px 0 rgba(0, 0, 0, 0.15), 2px 0 1px -2px rgba(0, 0, 0, 0.12);
            float: none;
            margin-left: 8px;
            padding: 3px 16px;
            color: $blue;
            transition: 0.3s;

            img,
            svg {
                height: 14px;
                margin-left: 8px;
            }

            &:hover,
            &:focus {
                box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.25), 2px 0 1px -2px rgba(0, 0, 0, 0.12);
                transition: 0.3s;
            }
        }
    }

    &.vendors {
        .MuiButtonBase-root {
            background: $darkblue;
            color: white;
        }
    }

    &.staff-access {
        .MuiButtonBase-root {
            background: #187c7c;
            color: white;
        }
    }
}

// 3. internal login
.internal-login {
    left: 0;
    // margin-right: auto;
    // margin-left: -50px;

    .container {
        display: flex;

        > div:first-child {
            display: flex;
            flex-wrap: wrap;
            flex: 2 0 325px;
            justify-content: flex-start;
            align-content: flex-start;
            max-width: 900px;
        }

        > div:nth-child(2) {
            max-width: 440px;
        }
    }

    @media (min-width: 1507px) {

        .container,
        .container-sm,
        .container-md,
        .container-lg,
        .container-xl {
            max-width: 1400px;
        }
    }

    @media (min-width: 1227px) and (max-width: 1506px) {

        .container,
        .container-sm,
        .container-md,
        .container-lg,
        .container-xl {
            max-width: 1200px;
        }
    }

    @media (min-width: 1071px) and (max-width: 1226px) {
        .container {
            max-width: 970px;

            > div:first-child {
                max-width: 900px;
            }

            > div:nth-child(2) {
                max-width: 300px;
            }
        }
    }

    @media (max-width: 1070px) {
        .container {
            max-width: 700px;

            > div:first-child {
                max-width: 100%;
            }

            > div:nth-child(2) {
                max-width: 300px;
            }
        }
    }

    .jumbotron-action {
        $button-border-radius: 5px;

        display: flex;
        flex-direction: column;
        color: #303030;
        margin: 0 30px 30px 0;
        padding: 15px 15px 10px;
        border-radius: $button-border-radius;
        width: 265px;
        min-height: 165px;
        max-height: 185px;

        > div:first-child {
            flex: 0 0 70px;
            margin-bottom: 10px;
        }

        > div:nth-child(2) {
            flex: 1 0 auto;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        button {
            min-width: 300px !important;
            margin-bottom: 0 !important;
            box-shadow: 0 1px 8px 0 rgba(0, 0, 0, .35), 0 3px 1px -2px rgba(0, 0, 0, .12);

            &:hover {
                box-shadow: 0 4px 15px 0 rgba(0, 0, 0, .35), 0 3px 6px -2px rgba(0, 0, 0, .20);
            }

            .MuiButton-label {
                // width: 175px;
                padding-left: 15px;
                line-height: initial;
                text-align: right;
                justify-content: flex-end;
            }

            .chevron-right {
                right: 0;
                margin-left: auto;
                border-radius: 0 $button-border-radius $button-border-radius 0;
            }
        }

        &.ecps {
            .chevron-right {
                background: linear-gradient(120deg, #038a6b, #04bd93) !important;
            }

            .btn-guide {
                &::before {
                    background: #05cda0 !important;
                }
            }
        }

        &.sbir {
            .chevron-right {
                background: linear-gradient(120deg, #832f27, #b44035) !important;
            }

            .btn-guide {
                &::before {
                    background: #832f27 !important;
                }
            }
        }

        &.ecns {
            .chevron-right {
                background: linear-gradient(120deg, #733ca6, #9147c1) !important;
            }

            .btn-guide {
                &::before {
                    background: #7507dc !important;
                }
            }
        }

        &.bidboard {
            .chevron-right {
                background: linear-gradient(120deg, #a55a23, #db792f) !important;
            }

            .btn-guide {
                &::before {
                    background: #c15608 !important;
                }
            }
        }

        &.erds {
            .chevron-right {
                background: linear-gradient(120deg, #0580ad, #07afed) !important;
            }

            .btn-guide {
                &::before {
                    background: #0580ad !important;
                }
            }
        }
    }

    .jumbotron-notice--notice {
        padding: 30px;
        font-size: 12px;
        background-color: rgba(0, 0, 0, .4);
        border-radius: 7px;
        color: $white;

        p:last-child {
            margin-bottom: 0;
        }
    }
}

.internal-page-tabs {
    .nav-tabs {
        border-bottom: none;

        a {
            border: 0;
            cursor: pointer;
            color: #000;
            font-size: 15px;
            font-weight: 500;
            padding: 0.5rem 1.25rem;
            text-transform: uppercase;
            transition: .3s;
            border: none;
            background-color: #d5d5d5;
            margin-right: 10px;
            // box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);

            .badge-pill {
                margin-left: 7px;
                padding: .45em .6em;
                border-radius: 15px;
                position: relative;
                top: -3px;

                &.badge-primary {
                    background-color: #20558A;
                }

                &.badge-danger {
                    background-color: #DC004E;
                }
            }

            .count-icon {
                background: $blueTab;
                color: #fff;
                border-radius: 100%;
                font-size: 12px;
                margin-left: 8px;
                padding: 8px 8px;
                position: relative;
                top: -1px;
            }

            &.active,
            &.active:hover {
                background: $blueTab;
                color: #fff;

                .count-icon {
                    background: #fff;
                    color: #000;
                }
            }

            &:hover {
                // background: darken($bg-color, 10%);
                background: #fff;
                box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
                transition: .3s;
            }
        }

        .multiline-tab-name {
            line-height: 1.1;

            .tab-name {
                font-weight: 700;
            }

            .tab-description {
                font-weight: 100;
            }
        }
    }

    .tab-content {
        border-top: 5px solid $blueTab;
        border-radius: 0 5px 0 0;
        background-color: #fff;
        position: relative;
        padding: 20px;

        .tile-section {
            &::before {
                display: none;
            }

            .section-heading {
                padding-bottom: 25px;
                // padding-left: 15px;
            }
        }

        .accordion-flex-heading {
            display: flex;
            justify-content: space-between;
        }

        .above-tab-actions {
            position: relative;
            top: unset;
            right: unset;

            button {
                font-size: 13px;
                margin-right: 5px;
                padding: 3px 15px;
                text-transform: capitalize;

                &:last-child {
                    margin-right: 0;
                }
            }

            img,
            svg {
                height: 14px;
                margin-right: 10px;
                position: relative;
                top: 0;
            }
        }
    }
}

// 4. external links
a.icon-link {
    color: $blue;
    font-weight: 500;
    padding-right: 22px;
    position: relative;

    &::after {
        content: '';
        background: url('./../../Images/icons/external-link.svg');
        background-size: cover;
        display: block;
        height: 15px;
        position: absolute;
        right: 0;
        top: 2px;
        width: 15px;
    }
}

// 5. project banners and solicitation headings
.project-banner-preview {
    background: white;
    position: relative;
    width: 100%;
    box-shadow: $shadow;
    margin-bottom: 20px;
    border-radius: 5px;
    padding: 10px 20px;
    transition: 0.3s;

    &:hover,
    &:focus {
        box-shadow: $shadow-strong;
        transition: 0.3s;
    }

    &::before {
        content: '';
        display: block;
        width: 5px;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
    }

    &.solicitation::before {
        background: #039b78;
    }

    &.combined::before {
        background: #825c83;
    }

    &.sources::before {
        background: #0580ad;
    }

    &.pre-notice::before {
        background: #e8c045;
    }

    &.other::before {
        background: #757575;
    }

    .project-banner-header {
        display: flex;
        justify-content: space-between;

        .project-type {
            font-size: 12px;
            color: #757575;
            text-transform: uppercase;
        }
        // a {
        //   color: $blue;
        //   font-weight: 500;
        //   padding-right: 22px;
        //   position: relative;
        //   &::after {
        //     content: '';
        //     background: url('/images/external-link.svg');
        //     background-size: cover;
        //     display: block;
        //     height: 15px;
        //     position: absolute;
        //     right: 0;
        //     top: 2px;
        //     width: 15px;
        //   }
        // }
    }

    .project-banner-title {
        font-weight: 500;
        font-size: 18px;
        color: #2b2a2d;
        line-height: 1.2em;
        line-break: anywhere;
    }

    .project-banner-footer {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;

        table {
            thead {
                th {
                    background: transparent;
                    font-size: 12px;
                    font-weight: 400;
                    padding: 0 20px 0 0;

                    &:last-child {
                        padding-right: 0;
                    }
                }
            }

            td {
                padding: 0 20px 0 0;
                font-size: 16px;
                font-weight: 500;
                color: #0c2d42;

                &:last-child {
                    padding-right: 0;
                }
            }
        }

        .button-container {
            display: flex;
            flex-direction: column;
            justify-content: center;

            .btn-white {
                font-size: 13px;
                padding: 3px 16px;
                text-transform: unset;
                color: #555;
                border-color: #555;
            }
        }
    }

    &.combined::before {
        background: $darkmedblue;
    }

    &.solicitation::before {
        background: #039b78;
    }

    &.award::before {
        background: #825c83;
    }
}

.solicitation-landing {
    .solicitation-actions {
        margin-top: -40px;
        margin-bottom: 35px;
        padding-top: 40px;
        position: relative;

        @include media-breakpoint-down(md) {
            margin-top: 0px;
        }

        .new-solicitation-btn {
            background-color: #1f558a;
            color: #fff;
            position: absolute;
            right: 0;
            top: 0;
            padding: 7px 30px;
            font-weight: 500;

            img {
                fill: #fff;
                left: -7px;
                position: relative;
                top: -1px;
                width: 13px;
                height: 13px;
            }

            @media (max-width: 441px) {
                width: 100%;
            }
        }
    }

    .project-banner-preview {
        .banner-details {
            width: 100%;

            table {
                width: 100%;

                td {
                    vertical-align: top;
                    // &:last-child {
                    //   vertical-align: bottom;
                    // }
                }

                td,
                th {
                    &:nth-child(2) {
                        width: 125px;
                        word-break: break-word;
                    }

                    &:nth-child(3) {
                        width: 200px;
                        word-break: break-word;
                    }

                    &:nth-child(4) {
                        width: 205px;
                        word-break: break-word;
                    }
                }

                @media (max-width: 992px) {
                    tr {
                        border: none;

                        td {

                            &:first-child,
                            &:last-child {
                                &:before {
                                    display: none;
                                }
                            }
                        }
                    }
                }

                @media (max-width: 1252px) {
                    td:not(:first-child):not(:last-child) {
                        font-size: 14px;
                    }

                    td:first-child {
                        padding-bottom: 40px;
                    }

                    td:last-child {
                        position: relative;

                        button {
                            position: absolute;
                            bottom: 0;
                            right: 0;
                        }
                    }

                    .solicitation-action-btns {
                        button {
                            min-width: 50px;

                            span {
                                font-size: 20px;
                            }

                            span + span {
                                font-size: 11px;
                            }
                        }
                    }
                }

                @media (max-width: 600px) {
                    tr {
                        td:last-child {
                            margin-top: 5px;
                            min-height: 30px;
                            position: relative;
                            width: 100%;

                            button {
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }

        .solicitation-action-btns {
            margin-top: -17px;
            display: flex;

            @include media-breakpoint-down(md) {
                margin-top: 0px;
            }

            .btn {
                background-color: #e9f1f6;
                border: none;
                margin-right: 10px;
                border-radius: 4px;
                color: #1f558a;
                padding: 7px;
                min-width: 85px;

                span {
                    font-size: 30px;
                    line-height: 1em;
                    display: block;
                }

                span + span {
                    font-size: 13px;
                    font-family: 'Roboto Condensed';
                }
                //&:last-child {
                //  margin-right: 10px !important;
                //  height: 27px;
                //}

                &:hover {
                    box-shadow: none;
                }
            }
        }

        .view-solicitation-btn {
            background-color: #e9f1f6;
            border: 0;
            color: #1f558a;
            font-weight: 500;
            font-size: 13px;
            min-width: 150px;
            float: right;

            img {
                width: 10px;
                height: 10px;
                right: -3px;
                position: relative;
                top: -1px;
            }

            &:hover,
            &:focus {
                background: darken(#e9f1f6, 10%);
                box-shadow: none;
                transition: .3s;
            }
        }
    }

    .filtered-params-tags {
        display: flex;
        align-items: baseline;
    }


    .filtered-params {
        margin-bottom: 15px;
        margin-right: 35px;
        font-size: 18px;

        .filtered-count {
            font-weight: 700;
            color: #005a8a;
        }
    }
}

$btn-colors: #006ba3, #0097ac, #00a37c, #008d60, #008264, #00470c;

.solicitation-filter-btns {
    display: flex;
    margin: 10px 0 10px 0;

    @for $i from 1 through length($btn-colors) {
        button:nth-child(#{length($btn-colors)}n+#{$i}) {
            border-top: 4px solid nth($btn-colors, $i);
            color: darken(nth($btn-colors, $i), 5%);
            margin-bottom: 15px;

            &.active {
                background: darken(nth($btn-colors, $i), 5%);
                border-top: 4px solid darken(nth($btn-colors, $i), 5%);
                color: $white;

                span + span {
                    color: $white;
                }
            }
        }
    }

    .btn-wrapper {
        min-width: 50%;

        &:first-child {
            margin-right: 10px;
        }
    }

    button {
        height: 120px;
        // width: 30%;
        width: 16.6666666667%;
        background-color: #fff;
        margin-right: 20px;
        border-radius: 4px;
        border-right: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
        border-left: 1px solid #ccc;
        box-shadow: 0px 3px #ddd;
        // border-right: 1px solid #ccc;
        // border-bottom: 1px solid #ccc;
        // border-left: 1px solid #ccc;
        box-shadow: $shadow;
        text-align: top;
        position: relative;

        &:hover,
        &:focus {
            box-shadow: $shadow-strong;
        }

        &.active {
            color: #fff;

            div {
                color: #fff;
            }

            &:nth-child(0n+1) {
                background-color: #005a8a;
                background: linear-gradient(0deg, #006ba3 0%, #005a8a 100%);
            }

            &:nth-child(1n+1) {
                background-color: #008193;
                background: linear-gradient(0deg, #0097ac 0%, #008193 100%);
            }

            &:nth-child(2n+1) {
                background-color: #008a69;
                background: linear-gradient(0deg, #00a37c 0%, #008a69 100%);
            }

            &:nth-child(3n+1) {
                background-color: #00744f;
                background: linear-gradient(0deg, #008d60 0%, #00744f 100%);
            }

            &:nth-child(4n+1) {
                background-color: #006950;
                background: linear-gradient(0deg, #008264 0%, #006950 100%);
            }

            &:nth-child(5n+1) {
                background-color: #002e08;
                background: linear-gradient(0deg, #00470c 0%, #002e08 100%);
            }
        }

        span,
        div {
            display: block;
            line-height: 1em;
            // white-space: nowrap;
            font-size: 2rem;
        }

        span + span,
        div + div {
            font-size: 1vw;
            margin-top: 5px;
            font-size: 15px;
            color: #555;
        }

        &:last-child {
            margin-right: 0;
        }

        div + div {
            height: 2em;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    @media (max-width: 992px) {
        flex-wrap: wrap;
        width: 100%;
        justify-content: space-between;

        button {
            width: calc(33.3% - 20px);

            &:first-child,
            &:nth-child(2),
            &:nth-child(3) {
                margin-bottom: 10px;
            }

            &:nth-child(3) {
                margin-right: 0;
            }

            span {
                font-size: 30px;
            }

            span + span {
                font-size: 15px;
            }
        }
    }

    @media (max-width: 762px) {
        button {
            width: calc(50% - 10px);

            &:first-child,
            &:nth-child(2),
            &:nth-child(4) {
                margin-bottom: 10px;
            }
        }
    }

    @media (max-width: 441px) {
        button {
            width: 100%;
            margin-bottom: 10px;
        }
    }
}

// 6. chevron list
.chevron-list {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 100%;
    height: 100%;
    padding: 1px 0 0;
    list-style: none;

    &:focus {
        outline: none;
    }

    li {
        &.outer-link {
            position: relative;
            background: white;
            border-radius: 5px;
            box-shadow: $shadow;
            margin-bottom: 10px;

            &::before {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 4px;
                content: '';
                display: block;
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
            }
        }

        &.technical-doc {
            .outer-link {
                &::before {
                    background: #0587b6;
                }

                .chevron-right {
                    background: linear-gradient(#047199 30%, #059bd2 80%);

                    &::after {
                        background-image: url('./../../Images/site-unique/doc-technical.svg');
                    }
                }
            }
        }

        &.business-doc {
            .outer-link {
                &::before {
                    background: #05cda0;
                }

                .chevron-right {
                    background: linear-gradient(#038a6b 30%, #04bc92 80%);

                    &::after {
                        background-image: url('./../../Images/site-unique/doc-business.svg');
                    }
                }
            }
        }

        &.business-excel-doc {
            .outer-link {
                &::before {
                    background: #05cda0;
                }

                .chevron-right {
                    background: linear-gradient(#038a6b 30%, #04bc92 80%);

                    &::after {
                        background-image: url('./../../Images/site-unique/doc-business-excel.svg');
                    }
                }
            }
        }

        &.misc-doc {
            .outer-link {
                &::before {
                    background: #9583c1;
                }

                .chevron-right {
                    background: linear-gradient(#9583c1 30%, #bda9e8 80%);

                    &::after {
                        background-image: url('./../../Images/site-unique/doc-person.svg');
                    }
                }
            }
        }

        &.dark-blue-doc {
            .outer-link {
                &::before {
                    background: #20558a;
                }

                .chevron-right {
                    background: linear-gradient(#20558a 30%, #2c75bd 80%);

                    &::after {
                        background-image: url('./../../Images/icons/plus-white.svg');
                    }
                }
            }
        }

        &.technical-doc,
        &.redacted-doc {
            .upload-span {
                max-width: 100%;

                .technical-doc-upload {
                    color: white;
                    display: inline-block;
                    background: darken(#367FA8, 2.5%);
                    border: 0;
                    border-radius: 25px;
                    padding: 4px 15px 4px 30px;
                    margin-bottom: 10px;
                    margin-left: 10px;
                    white-space: nowrap;
                    -webkit-user-select: none;
                    user-select: none;
                    cursor: pointer;
                    font-size: 13px;
                    transition: .3s;

                    &::after {
                        background-image: url('./../../Images/icons/upload-white.svg');
                        content: '';
                        display: block;
                        height: 14px;
                        left: 25px;
                        position: absolute;
                        top: 5px;
                        width: 14px;
                    }

                    &:hover,
                    &:focus {
                        background: darken(#367FA8, 10%);
                    }
                }

                .name-container {
                    display: flex;
                    max-width: 100%;
                    position: relative;
                }

                .file-chosen {
                    display: inline-block;
                    font-size: 12px;
                    margin-left: 10px;
                    overflow: hidden;
                    position: relative;
                    text-overflow: ellipsis;
                    top: 5px;
                    white-space: nowrap;

                    @media (max-width: map-get($grid-breakpoints, xl)) {
                        display: block;
                        float: right;
                    }
                }
            }

            .outer-link::before {
                background: #0587B6;
            }

            .chevron-right {
                background: linear-gradient(#047199 30%, #059BD2 80%);

                &::after {
                    background-image: url('./../../Images/site-unique/doc-technical.svg');
                }
            }
        }

        &.no-doc {
            .outer-link {
                background: #E4E5E7;
                display: flex;
                padding: 10px 15px;

                &::before {
                    background: transparent;
                }

                img {
                    width: 40px;
                }

                .document-details {
                    margin-right: 0;
                    margin-left: 20px;

                    .document-name {
                        font-size: 13px;
                        color: #646464;
                    }
                }
            }
        }

        $chevron-width: 100px;

        .chevron-right {
            position: absolute;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
            top: 0;
            right: 0;
            height: 100%;
            width: $chevron-width;

            img,
            svg {
                height: 100%;
                width: $chevron-width;
                left: -5px;
                position: absolute;

                @media (max-width: 600px) {
                    left: -6px;
                }
            }

            &::after {
                background-image: url('./../../Images/icons/plus-white.svg');
                content: '';
                display: inline-block;
                height: 40px;
                left: 37%;
                margin: auto;
                position: absolute;
                top: 26%;
                width: 40px;
            }

            .arrow-right {
                &::before {
                    background-image: linear-gradient(to right top, #fff 0%, #fff 50%, transparent 0%);
                    background-repeat: no-repeat;
                    background-size: cover;
                    content: '';
                    display: block;
                    height: 50%;
                    min-width: 20px;
                    position: absolute;
                    top: 0;
                }

                &::after {
                    background-image: linear-gradient(to right bottom, #fff 0%, #fff 50%, transparent 0%);
                    background-repeat: no-repeat;
                    background-size: cover;
                    bottom: 0;
                    content: '';
                    display: block;
                    height: 50%;
                    min-width: 20px;
                    position: absolute;
                    // background-size: 100% 100%;
                }
            }
        }

        .document-details {
            padding: 10px;
            margin-right: 75px;

            .document-name {
                font-size: 16px;
                font-weight: 500;
            }

            .document-file {
                max-width: 75%;
            }

            .material-actions {
                float: right;
                position: relative;
                top: -35px;
            }

            .inner-doc-flex {
                display: flex;
                position: relative;
                white-space: nowrap;

                .filename_base {
                    display: block;
                    max-width: 100%;
                    overflow: hidden;
                    position: relative;
                    text-overflow: ellipsis;
                }
            }
        }

        .required-doc {
            color: $blue;
            display: inline-block;
            font-size: 14px;
            font-weight: 500;
            margin-right: 8px;
        }

        .chevron-after-text {
            margin-top: 5px;
        }
    }
    // @media (max-width: map-get($grid-breakpoints, lg)) {
    // }
}

.listed-cards-wrap {
    // background-color: #fff;
    margin-top: 30px;
    padding: 15px;

    .filter {
        margin-bottom: 10px;
        align-items: center;
    }

    .MuiFormControl-root .MuiInputBase-root {
        background-color: #fff;
    }

    .listed-card {
        margin-bottom: 15px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
        padding: 10px;
        border-radius: 5px;
        background-color: #fcfcfc;
        min-width: 275px;
        border-left: 5px solid $color-box-blue;



        &.card-saved {
            border-left-color: $color-box-brightGreen;
        }

        &.card-pendingforeligible {
            border-left-color: $color-box-lightPurple;
        }

        &.card-eligibleforreview {
            border-left-color: $color-box-purple;
        }

        &.card-reviewinprogress {
            border-left-color: $color-box-Lightorange;
        }

        &.card-reviewcompleted {
            border-left-color: $color-box-orange;
        }

        &.card-offered {
            border-left-color: $color-box-maroon;
        }

        &.card-notoffered {
            border-left-color: $color-box-lightPink;
        }

        &.card-offer-accepted {
            border-left-color: $color-box-green;
        }

        &.card-offer-declined {
            border-left-color: $color-box-lightGreen;
        }

        &.card-alternate {
            border-left-color: $color-box-gray;
        }

        &.card-starred {
            border-left-color: $color-box-yellow;
        }

        &.card-joinedniaid {
            border-left-color: $color-box-skyBlue;
        }

        &.card-withdrawn {
            border-left-color: $color-box-brown;
        }

        .applicant-info {
            padding: 0 5px;
            border-bottom: 2px solid $gray-200;
        }

        .name {
            font-weight: 600;
            font-size: 20px;
        }

        .status {
            text-align: center;
            margin-left: 10px;
            font-size: 12px;
            padding: 2px 8px;
            border-radius: 13px;
            border: 1px solid #9ba9ba;
            position: relative;
            padding-left: 20px;

            &:before {
                content: '';
                display: block;
                position: absolute;
                width: 10px;
                height: 10px;
                border-radius: 50%;
                left: 5px;
                top: 5px;
            }

            &.status-saved:before {
                background-color: $color-box-brightGreen;
            }

            &.status-all:before {
                background-color: $color-box-blue;
            }

            &.status-pendingforeligible:before {
                background-color: $color-box-lightPurple;
            }

            &.status-eligibleforreview:before {
                background-color: $color-box-purple;
            }

            &.status-reviewinprogress:before {
                background-color: $color-box-Lightorange;
            }

            &.status-reviewcompleted:before {
                background-color: $color-box-orange;
            }

            &.status-offered:before {
                background-color: $color-box-maroon;
            }

            &.status-notoffered:before {
                background-color: $color-box-lightPink;
            }

            &.status-offer-accepted:before {
                background-color: $color-box-green;
            }

            &.status-offer-declined:before {
                background-color: $color-box-lightGreen;
            }

            &.status-alternate:before {
                background-color: $color-box-gray;
            }

            &.status-starred:before {
                background-color: $color-box-yellow;
            }

            &.status-joinedniaid:before {
                background-color: $color-box-skyBlue;
            }

            &.status-withdrawn:before {
                background-color: $color-box-brown;
            }
        }

        .star {
            width: 35px;
            height: 35px;
            position: relative;
            cursor: pointer;

            &:after {
                content: "";
                display: block;
                position: absolute;
                width: 30px;
                height: 30px;
                background-repeat: no-repeat;
                background-size: 2em;
            }

            &.star-filled:after {
                @include svg-star-filled(#f5c459);
            }

            &.star-outline:after {
                @include svg-star-outline(#ddd);
            }
        }

        .title {
            font-size: 12px;
            // font-weight: 600;
            margin: 10px 0 5px 0;
            line-height: 1.1em;
            color: #727272;
        }

        hr {
            margin: 0;
        }

        .description {
            font-size: 14px;
            color: #5b5c5c;
            margin: 0;
            font-weight: 500;
            // text-overflow: ellipsis;
            // white-space: nowrap;
            // overflow: hidden;
            line-height: 1.1em;
        }
        // .css-pwngrv-MuiPaper-root-MuiCard-root {
        //   border: 0;
        //   box-shadow: none;
        //   padding: 0;
        //   background-color: #fcfcfc;
        //   .css-46bh2p-MuiCardContent-root, .css-1t6e9jv-MuiCardActions-root {
        //     padding: 0;
        //   }
        // }
    }
}

// Application Form page

.application-info {
    width: 100%;
    z-index: 1;
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 10px 30px;
    background-color: #E6E6E9;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.35), 0 1px 2px rgba(0, 0, 0, 0.25);

    .title {
        margin-right: 5px;
    }

    .description {
        font-weight: 500;
    }
}

.left-border-box {
    border-left: 2px solid #a4a4a4;
}

.main-content-wrapper {
    display: flex;


    .document-box {
        margin: 30px;
        padding: 20px;
        position: relative;
        width: 70%;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
        background-color: #fff;

        h3 {
            text-align: center;
            color: $gray-800;
        }

        .btn-edit {
            position: absolute;
            top: 15px;
            right: 15px;
        }
    }

    .sidebar-right {
        background-color: #fff;
        width: 30%;
        padding: 30px 20px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
        // height: 100%;

        ul {
            padding: 0;
            margin: 0;

            li {
                list-style: none;
            }
        }
    }
}


// Application Form

.MuiStep-completed {
    .step-icon {
        border: none;
        &.step-icon::before {
            background-color: $darkmedblue;
            border-color: $darkmedblue;
        }
    }

    // + .MuiStep-root:not(.MuiStep-completed) {
    //     .step-icon {

    //         &.step-icon:before {
    //             background-color: $darkmedblue;
    //             border-color: $darkmedblue;
    //             // box-shadow: 0 0 10px $softblue;
    //         }
    //     }
    // }
}

.MuiFormControl-root {
    .MuiFormLabel-asterisk {
        color: red;
    }
}

.MuiFormLabel-asterisk {
    color: red;
}

.MuiPaper-root {
    .MuiStep-root {
        &.active {
            .step-icon {
                &.step-icon::before{
                    background-color: $darkmedblue;
                    border-color: $darkmedblue;
                }
            }

            .MuiStepLabel-label.MuiStepLabel-active {
                color: $darkmedblue;
            }
        }
    }
}


.MuiStepConnector-active .MuiStepConnector-line,
.MuiStepConnector-completed .MuiStepConnector-line {
    border-color: $darkmedblue;
}

.MuiStepConnector-lineHorizontal {
    border-top-width: 5px !important;
}


.MuiStep-root:not(.MuiStep-completed) + .MuiStep-root:not(.MuiStep-completed) {
    .step-icon {
        &:before {
            background-color: #b4b4b4;
            border: 3px solid #b4b4b4;
        }
    }
}

.step-icon {
    cursor: pointer;
    z-index: 1;

    &:before {
        content: '';
        display: block;
        width: 50px;
        height: 50px;
        background-size: 30px;
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 50px;
        // background-color: #b4b4b4;
        background-color: #b4b4b4;
        border: 3px solid #b4b4b4;
    }

    &.step-personalinformation:before {
        @include person(white);
    }

    &.step-diversitystatement:before {
        @include prize(white);
        // background-color: #2b8537;
    }

    &.step-researchinterests:before {
        @include person-search(white);
        // background-color: #23ad8d;
    }

    &.step-applicationsubmission:before {
        @include document(white);
        // background-color: #08a3b5;
    }

    &.step-applicationvalidation:before {
        @include shield(white);
    }
}


.num-warning {
    border: 1px solid #3e567b;
    padding: 3px;
    color: #fff;
    display: inline-block;
    background-color: #3272a7;
    border-radius: 50px;
    min-width: 30px;
    text-align: center;
    font-size: 18px;
    margin-right: 8px;
}

.icon-passed {
    position: relative;
    padding-left: 50px;

    &:before {
        content: '';
        position: absolute;
        @include done(white);
        background-color: $green;
        border-radius: 50px;
        width: 35px;
        height: 35px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 30px;
        right: 10px;
        top: -6px;
    }
}


.MuiStepConnector-alternativeLabel {
    top: 24px !important;
    z-index: 0;
}


// Rich Text Field
.ck.ck-content:not(.ck-comment__input *) {
    height: 300px;
    overflow-y: auto;
}
/*.ck-blurred.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline {
  min-height: 300px;
}*/

.warning-icon {
    position: relative;
    padding-left: 20px;

    &:before {
        content: '';
        position: absolute;
        @include warning($red);
        width: 18px;
        height: 18px;
        background-size: 18px;
        background-position: center;
        background-repeat: no-repeat;
        left: 0;
        top: 0px;
    }
}

.elegibilityform-chevron {
    padding: 0;
    display: flex;
    justify-content: flex-start;

    .title {
        font-size: 24px;
    }

    .btn-back {
        background-color: #fff;
        border: 1px solid#8A8A8A;
        border-radius: 4px;
    }

    .box-with-chevron {
        position: relative;
        background-color: #F2F2F2;
        padding: 20px 36px;
        // box-shadow: 0 2px 8px rgba(0, 0, 0, 0.35), 0 1px 2px rgba(0, 0, 0, 0.25);

        &:after {
            content: '';
            display: block;
            position: absolute;
            height: 100%;
            width: 4.5em;
            top: 0;
            right: 0;
            background: conic-gradient(from -30deg, #E6E6E9 0deg, #E6E6E9 240deg, transparent 240deg, transparent 360deg);
        }
    }
}
