.vertical-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

html,
body {
    background-color: $bg-color;
    font-family: $font-family-base;
    font-size: $font-size-base !important;
    height: 100%;
    min-height: calc(100vh - 201px);
    min-width: 320px;
    width: 100%;
    position: relative;
    display: table;
}

html body {
    background-color: $bg-color;
}

a {
    color: #165a96;
}

.jumbotron {
    background-size: cover;
    border-radius: 0;
    ;
}

// button overrides for material-ui
.MuiButtonBase-root {
    text-transform: capitalize;
    // &.btn-secondary {
    //   background: $secondary;
    //   color: $white;
    //   transition: 0.3s;
    //   &:hover {
    //     background: darken($secondary, 10%);
    //   }
    // }
}

li.MuiButtonBase-root.MuiMenuItem-root {
    justify-content: flex-start;
    display: flex;
    padding: 6px 16px;
}

// hide linked SVGs before they are replaced with inline SVGs by the script
// img[src$=".svg"] {
//     display: none;
// }

$reader-icon-size: 22px;
$icon-margin: $reader-icon-size * 0.4;

// #readers {
//   margin-bottom: 20px;
//   text-align: right;
// }

.reader-download {
    margin-right: $icon-margin;

    &:first-child {
        margin-left: $icon-margin;
    }
}

.icon-reader {
    height: $reader-icon-size;
    width: $reader-icon-size;
}

.main-content {

    h1,
    h5 {
        color: $navy;
        font-size: 1.25rem;
    }
}

.tile-wrapper {
    margin-bottom: 30px;
    padding: 0 15px;

    &:first-child {
        padding-left: 0;
    }

    &:last-child {
        padding-right: 0;
    }

    @media (max-width: map-get($grid-breakpoints, md)) {
        padding: 0;
    }

    h1 {
        font-size: 20px;
        font-weight: 500;
    }
}

.scroll-wrapper {
    position: relative;

    &::after {
        background: rgb(255, 255, 255);
        background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
        bottom: 0;
        content: '';
        height: 10px;
        left: 0;
        position: absolute;
        // width: calc(100% + 4px);
        width: 100%;
    }
}

.scroll-container {
    margin: 0 -10px 0 -5px;
    overflow-x: auto;
    overflow-y: scroll;
    padding: 0 5px;
    position: relative;
    /* width */
    &::-webkit-scrollbar {
        width: 6px;
    }
    /* Track */
    &::-webkit-scrollbar-track {
        background: $bg-color;
        border-radius: 3px;
    }
    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: #bbb;
        border-radius: 3px;
    }
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }

    > .btn {
        border-radius: 10px;
        margin-bottom: 12px;
        text-align: left;
        width: 100%;
    }
}

.tile {
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.15), 0 3px 1px -2px rgba(0, 0, 0, 0.12)0;
    padding: 30px;
    position: relative;

    @media (max-width: map-get($grid-breakpoints, sm)) {
        padding: 15px;
    }


    &::before {
        background-color: $primary-light;
        content: '';
        height: 4px;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }

    .scroll-container {
        max-height: 240px;
        min-height: 240px;

        @media (max-width: map-get($grid-breakpoints, md)) {
            max-height: none;
            min-height: none;
            overflow: auto;
        }
    }
}

.tile-secondary {
    &::before {
        background-color: $secondary;
    }
}

label {
    font-size: 15px;
    font-weight: 500;
}

.form-control {
    background-color: $bg-color;
    border: 0;
    border-radius: 10px;

    &:focus {
        background-color: $bg-color;
    }
}

.form-optional {
    color: #757575;
    font-size: 13px;
    font-style: oblique;
}

.file-upload {
    background-color: $bg-color;
    border: 2px dashed #aac6b0;
    border-radius: 10px;
    color: $primary-dark;
    min-height: 100px;
    padding: 4px;

    input[type='file'] {
        height: 100%;
        min-height: 88px;
        padding: 28px 0;
        // display: none;
        width: 100%;
    }
}

// #notes {
//   min-height: 100px;
// }

.btn {
    font-size: 16px;
    line-height: 1.25em;

    &:hover,
    &:focus {
        box-shadow: 0 2px 3px rgba(0, 0, 0, 0.25);
        // 0px 1px 8px 0px rgba(0, 0, 0, 0.15),
        // // 0px 2px 4px 0px rgba(0, 0, 0, 0.14),
        // 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
    }
}

.sub-title {
    color: #676767;
}

.btn {
    border-radius: 4px;

    .sub-title {
        font-size: 13px;
        line-height: 1em;
    }

    &.btn-lowercase {
        text-transform: none;
    }

    &.btn-edit {
        text-transform: none;
        background: #fff;
        border: solid 1px #555;
        box-sizing: border-box;
        color: #555;
        padding-left: 20px;
        height: 30px;

        &:before {
            content: '';
            @include pencil($gray-800);
            width: 16px;
            height: 16px;
            background-size: 16px;
            position: absolute;
            top: 5px;
            left: 6px;
            transition: .3s;
        }
    }

    &.btn-mail {
        text-transform: none;
        background: #fff;
        // border: solid 1px #555;
        box-sizing: border-box;
        // color: #555;
        padding-left: 35px;

        &:before {
            content: '';
            @include mail($gray-500);
            width: 24px;
            height: 24px;
            background-size: 24px;
            background-repeat: no-repeat;
            position: absolute;
            top: 6px;
            left: 6px;
            transition: .3s;
        }
    }

    &.btn-refresh {
        text-transform: none;
        background: #fff;
        padding-left: 35px;
        border: solid 1px #555;
        color: #555;
        cursor: pointer;

        &:before {
            content: '';
            @include refresh(#555);
            width: 24px;
            height: 24px;
            background-size: 24px;
            background-repeat: no-repeat;
            position: absolute;
            top: 6px;
            left: 6px;
            transition: .3s;
        }

        &.btn-disabled:before {
            @include refresh(#bbb9ba);
        }
    }

    &.btn-next {
        border: 0;
        text-transform: none;
        color: #20558A;
        font-weight: 500;
        font-size: 15px;
        min-width: 150px;
        padding-right: 22px;
        position: relative;
        background-color: #E3EAF1;
        box-shadow: 0 2px 3px rgba(0, 0, 0, 0.25);

        &:before {
            content: '';
            @include arrow-right(#20558A);
            width: 20px;
            height: 20px;
            background-size: 20px;
            position: absolute;
            top: 9px;
            right: 2px;
        }

        &:hover,
        &:focus {
            background-color: #E3EAF1;
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.4);
            transition: .3s;
        }
    }

    &.btn-back {
        border: 0;
        text-transform: none;
        color: black;
        font-weight: 500;
        font-size: 15px;
        min-width: 150px;
        padding-left: 22px;
        position: relative;
        background-color: $gray-200;
        box-shadow: 0 2px 3px rgba(0, 0, 0, 0.25);

        &:before {
            content: '';
            @include arrow-left(black);
            width: 20px;
            height: 20px;
            background-size: 20px;
            position: absolute;
            top: 9px;
            left: 2px;
        }

        &:hover,
        &:focus {
            color: black;
            // background-color: transparent;
            // box-shadow: none;
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.4);
            transition: .3s;
        }
    }

    &.btn-reply {
        text-transform: none;
        background-color: #fff;
        color: #000;
        border: 1px solid#8A8A8A;
        border-radius: 4px;
        font-weight: 500;
        font-size: 15px;
        min-width: 150px;
        padding-left: 30px;
        position: relative;
        box-shadow: 0 2px 3px rgba(0, 0, 0, 0.25);

        &:before {
            content: '';
            @include reply(black);
            width: 20px;
            height: 20px;
            background-size: 20px;
            position: absolute;
            top: 7px;
            left: 4px;
            transform: scaleX(-1);
        }

        &:hover,
        &:focus {
            color: black;
            // background-color: transparent;
            // box-shadow: none;
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.4);
            transition: .3s;
        }
    }

    &.btn-reply-left {
        text-transform: none;
        background-color: #fff;
        color: #000;
        border: 1px solid#8A8A8A;
        border-radius: 4px;
        font-weight: 500;
        font-size: 15px;
        min-width: 150px;
        padding-left: 30px;
        position: relative;
        box-shadow: 0 2px 3px rgba(0, 0, 0, 0.25);

        &:before {
            content: '';
            @include reply(black);
            width: 20px;
            height: 20px;
            background-size: 20px;
            position: absolute;
            top: 7px;
            left: 4px;
        }

        &:hover,
        &:focus {
            color: black;
            // background-color: transparent;
            // box-shadow: none;
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.4);
            transition: .3s;
        }
    }

    &.btn-person {
        text-transform: none;
        background-color: $blue;
        color: #fff;
        border: 1px solid#8A8A8A;
        border-radius: 4px;
        font-weight: 500;
        font-size: 15px;
        min-width: 150px;
        padding-left: 30px;
        position: relative;
        box-shadow: 0 2px 3px rgba(0, 0, 0, 0.25);

        &:before {
            content: '';
            @include person(#fff);
            width: 20px;
            height: 20px;
            background-size: 20px;
            position: absolute;
            top: 7px;
            left: 4px;
        }

        &:hover,
        &:focus {
            background-color: #20558A;
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.4);
            transition: .3s;
        }
    }

    &.btn-print {
        text-transform: none;
        background-color: $blue;
        color: #fff;
        border: 1px solid#8A8A8A;
        border-radius: 4px;
        padding-left: 30px;
        position: relative;
        box-shadow: 0 2px 3px rgba(0, 0, 0, 0.25);

        &:before {
            content: '';
            @include print(#fff);
            width: 20px;
            height: 20px;
            background-size: 20px;
            position: absolute;
            top: 9px;
            left: 4px;
        }

        &:hover,
        &:focus {
            background-color: #20558A;
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.4);
            transition: .3s;
        }
    }

    &.btn-back-bdrs {
        // background-color: #e9f1f6;
        border: 0;
        text-transform: none;
        color: $gray-dark;
        font-weight: 500;
        font-size: 15px;
        min-width: 150px;
        padding-left: 22px;
        // float: right;
        position: relative;
        background-color: #fff;
        border-radius: 20px;
        box-shadow: 0 2px 3px rgba(0, 0, 0, 0.25);

        &::before {
            background: url('./../../Images/icons/icon-arrow-left.svg');
            background-size: cover;
            background-position: center;
            content: '';
            display: block;
            height: 10px;
            position: absolute;
            left: 8px;
            top: 10px;
            width: 10px;
        }

        &:hover,
        &:focus {
            color: black;
            // background-color: transparent;
            // box-shadow: none;
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.4);
            transition: .3s;
        }
    }

    &.btn-upload {
        padding-left: 30px;
        $background: $blue;
        $border: $background;
        $hover-background: darken($background, 5%);
        $hover-border: $hover-background;
        $active-background: $hover-background;
        $active-border: $active-background;
        /*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
        @include button-variant($background, $border, $hover-background, $hover-border, $active-background, $active-border);

        &::before {
            content: '';
            @include upload($white);
            width: 18px;
            height: 18px;
            background-size: 18px;
            position: absolute;
            left: 5px;
        }
    }

    &.btn-update {
        padding-left: 30px;
        $background: $green;
        $border: $background;
        $hover-background: darken($background, 5%);
        $hover-border: $hover-background;
        $active-background: $hover-background;
        $active-border: $active-background;
        text-transform: none;
        /*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
        @include button-variant($background, $border, $hover-background, $hover-border, $active-background, $active-border);

        &::before {
            content: '';
            @include upload($white);
            width: 24px;
            height: 24px;
            background-size: 24px;
            position: absolute;
            left: 5px;
        }

        &.btn-disabled:before {
            @include upload(#bbb9ba);
        }
    }


    &.btn-count {
        text-transform: none;

        .count-icon {
            background: #fff;
            color: #000;
            border-radius: 12px;
            font-size: 12px;
            margin-left: 8px;
            padding: 1px 8px;
            position: relative;
            top: -1px;
        }
    }

    &.btn-disabled,
    &.MuiButtonBase-root.btn-disabled.btn-primary {
        background-color: #E6E6E9;
        color: #bbb9ba;
        text-transform: none;
        border-color: #E6E6E9;
        pointer-events: none;

        .count-icon {
            background: #fff;
            color: #bbb9ba;
            border-radius: 12px;
            font-size: 12px;
            margin-left: 8px;
            padding: 1px 8px;
            position: relative;
            top: -1px;
        }

        &:hover,
        &:focus {
            background-color: #E6E6E9;
            color: #bbb9ba;
        }
    }
}

.btn-light {
    $background: $bg-color;
    $border: $background;
    $hover-background: darken($background, 5%);
    $hover-border: $hover-background;
    $active-background: $hover-background;
    $active-border: $active-background;
    /*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
    @include button-variant($background, $border, $hover-background, $hover-border, $active-background, $active-border);
}

.btn-dark {
    $background: #555;
    $border: $background;
    $hover-background: darken($background, 5%);
    $hover-border: $hover-background;
    $active-background: $hover-background;
    $active-border: $active-background;
    /*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
    @include button-variant($background, $border, $hover-background, $hover-border, $active-background, $active-border);
}

.btn-success,
.btn-success.MuiButtonBase-root {
    $background: $green;
    $border: $background;
    $hover-background: darken($background, 5%);
    $hover-border: $hover-background;
    $active-background: $hover-background;
    $active-border: $active-background;
    /*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
    @include button-variant($background, $border, $hover-background, $hover-border, $active-background, $active-border);
}

.btn-primary {
    $background: $blue;
    $border: $background;
    $hover-background: darken($background, 5%);
    $hover-border: $hover-background;
    $active-background: $hover-background;
    $active-border: $active-background;
    /*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
    @include button-variant($background, $border, $hover-background, $hover-border, $active-background, $active-border);
}

.btn-red {
    $background: $red;
    $border: $background;
    $hover-background: darken($background, 5%);
    $hover-border: $hover-background;
    $active-background: $hover-background;
    $active-border: $active-background;
    /*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
    @include button-variant($background, $border, $hover-background, $hover-border, $active-background, $active-border);
}

.btn-info {
    $background: $purple;
    $border: $background;
    $hover-background: darken($background, 5%);
    $hover-border: $hover-background;
    $active-background: $hover-background;
    $active-border: $active-background;
    /*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
    @include button-variant($background, $border, $hover-background, $hover-border, $active-background, $active-border);
}

.btn-outline-primary {
    $background: #fff;
    $border: $green;
    $hover-background: darken($background, 5%);
    $hover-border: $hover-background;
    $active-background: $hover-background;
    $active-border: $active-background;
    /*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
    @include button-variant($background, $border, $hover-background, $hover-border, $active-background, $active-border);
    border-width: 2px;
    color: $green;

    &:hover,
    &:focus {
        border-color: $green;
        color: $green;
    }
}

.btn-save {
    position: relative;
    padding-left: 30px;
    $background: $blue;
    $border: $background;
    $hover-background: darken($background, 5%);
    $hover-border: $hover-background;
    $active-background: $hover-background;
    $active-border: $active-background;
    /*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
    @include button-variant($background, $border, $hover-background, $hover-border, $active-background, $active-border);

    &:before {
        content: '';
        @include save($white);
        width: 18px;
        height: 18px;
        background-size: 18px;
        position: absolute;
        left: 5px;
    }
}



.btn {
    .icon-arrow {
        height: 16px;
        left: 2em;
        position: relative;
        top: -1px;
        vertical-align: middle;
        width: 16px;
    }

    &.btn-more {
        position: relative;
        height: 34px;
        width: 34px;

        &:before {
            content: '';
            display: block;
            position: absolute;
            background: url(../../Images/icons/more_vert_white_24dp.svg);
            width: 25px;
            height: 21px;
            top: 4px;
            left: 4px;
        }
    }

    &.btn-save {
        position: relative;
        padding-left: 30px;
        $background: $blue;
        $border: $background;
        $hover-background: darken($background, 5%);
        $hover-border: $hover-background;
        $active-background: $hover-background;
        $active-border: $active-background;
        /*button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%))*/
        @include button-variant($background, $border, $hover-background, $hover-border, $active-background, $active-border);

        &:before {
            content: '';
            @include save($white);
            width: 18px;
            height: 18px;
            background-size: 18px;
            position: absolute;
            left: 5px;
        }

        &.btn-disabled {
            background-color: #E6E6E9;
            color: #bbb9ba;

            &:before {
                @include save(#bbb9ba);
            }
        }
    }

    &.btn-close {
        position: relative;
        opacity: 0.5;

        &:after {
            content: '×';
            position: absolute;
            color: $navy;
            font-size: 35px;
            font-weight: 100;
        }

        &:hover,
        &:focus {
            opacity: 1;
            background-color: transparent;
            box-shadow: none;
        }
    }

    &.btn-download-icon {
        position: relative;

        &:before {
            content: '';
            @include download-file($teal);
            width: 30px;
            height: 30px;
            background-size: 30px;
            position: absolute;
            top: -9px;
            right: 10px;
            transition: .3s;
        }

        &:hover,
        &:focus {
            box-shadow: none;

            &:before {
                @include download-file($green-teal);
            }
        }
    }

    &.btn-delete-icon {
        position: relative;

        &:before {
            content: '';
            @include trash-bin(#cc4956);
            width: 30px;
            height: 30px;
            background-size: 30px;
            position: absolute;
            top: -9px;
            right: 0;
            transition: .3s;
        }

        &:hover,
        &:focus {
            box-shadow: none;

            &:before {
                @include trash-bin($red);
            }
        }
    }
}

.filter-btn {
    color: $navy;
    border-radius: 20px;
    font-size: 12px;
    padding: 5px 10px;
    height: 30px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
    background-color: #fff;

    .btn-close:after {
        top: -5px;
        font-size: 18px;
    }
}


.btn-flag {
    position: relative;
    outline: none !important;

    &:after {
        content: '';
        position: absolute;
        @include flag($gray-400);
        width: 30px;
        height: 30px;
        background-size: 30px;
        background-repeat: no-repeat;
    }
}

.btn-message {
    position: relative;
    outline: none !important;

    &:after {
        content: '';
        position: absolute;
        @include chat(#fff);
        background-color: #7395CE;
        width: 20px;
        height: 20px;
        background-size: 20px;
        padding: 15px;
        background-position: center;
        border-radius: 10px;
        background-repeat: no-repeat;
    }
}


.filtered-by-year,
.sort-by-select {
    min-width: 200px;

    &.MuiInputBase-root.MuiInput-root.filtered-by-year.MuiInputBase-formControl.MuiInput-formControl,
    .input.MuiSelect-nativeInput {
        background-color: #fff !important;

        .input.MuiSelect-nativeInput {
            background-color: #fff !important;
        }
    }
}

form .MuiFormControl-root .MuiInputBase-root.filtered-by-year .MuiInputBase-input.MuiSelect-select {
    background-color: #fff;
}

.login-buttons {
    .btn {
        height: 45px;

        + .btn {
            margin-left: 20px;

            @media (max-width: map-get($grid-breakpoints, sm)) {
                margin-left: 0;
                margin-top: 10px;
            }
        }
    }

    .btn-primary {
        font-size: 22px;
        padding-left: 2.5em;
        padding-right: 2.5em;
    }


    .btn-outline-primary {
        font-size: 20px;
        padding-left: 0.5em;
        padding-right: 0.5em;
    }

    @media (max-width: map-get($grid-breakpoints, sm)) {

        .btn-primary,
        .btn-outline-primary {
            width: 100%;
        }
    }
}

.btn-outline-gray {
    font-size: 14px;
    padding: 2px 8px;
    border-radius: 13px;
    border: 1px solid #9ba9ba;
}

.form-buttons {
    margin-top: 15px;
    text-align: right;
}

.btn-about {
    background-color: transparent;
    border: none;
    color: #fff;
    position: relative;
    // padding-right: 30px;
    // &:after {
    //   content: '';
    //   display: block;
    //   position: absolute;
    //   @include launch($white);
    //   width: 20px;
    //   height: 20px;
    //   right: 8px;
    //   top: 10px;
    //   background-position: 0 0;
    //   background-repeat: no-repeat;
    //   background-size: 1em;
    // }

    &:hover {
        text-decoration: underline;
    }
}

.btn-contact {
    background-color: transparent;
    border: none;
    color: #fff;
    position: relative;
    // padding-right: 30px;
    // &:after {
    //   content: '';
    //   display: block;
    //   position: absolute;
    //   @include mail($white);
    //   right: 8px;
    //   top: 10px;
    //   width: 20px;
    //   height: 20px;
    //   background-position: 0 0;
    //   background-repeat: no-repeat;
    //   background-size: 1em;
    // }

    &:hover {
        text-decoration: underline;
    }
}

.modal-content {
    border-radius: $modal-radius;
}

.modal-body {
    padding: ($modal-padding * (2/3)) $modal-padding 0 $modal-padding;
}

.modal-header {
    padding: ($modal-padding / 3) ($modal-padding / 2) ($modal-padding / 3) $modal-padding;
}

.modal-header {
    background-color: $bg-color;
    border-bottom: 4px solid #e7e6e2;
    border-top-left-radius: $modal-radius;
    border-top-right-radius: $modal-radius;
}

.modal-footer {
    border: 0;
}

.icon-close {
    height: 20px;
    margin-top: 4px;
    opacity: 0.8;
    width: 20px;
}

.icon-success {
    height: 55px;
    width: 55px;
}

.modal-status {
    margin-bottom: $modal-padding * (2/3);

    .icon-success {
        vertical-align: middle;
    }

    .status-text {
        color: $green;
        margin-left: 10px;
        vertical-align: middle;
    }
}

.label {
    font-size: 16px;
    font-weight: 500;
}

.form-content {
    font-size: 14px;
    margin-bottom: 20px;

    a {
        text-decoration: underline;
    }
}

.tile-welcome {
    &::before {
        height: 100%;
        width: 4px;
    }

    h1 {
        color: #123b56;
        font-size: 32px;
        font-weight: 300;
        line-height: 1.5em;
        margin-top: -0.45em;

        .emphasis {
            font-size: 50px;
        }

        @media (max-width: map-get($grid-breakpoints, md)) {
            font-size: 24px;

            .emphasis {
                font-size: 40px;
            }
        }
        // @media (max-width: map-get($grid-breakpoints, sm)) {
        //     font-size: 20px;
        //     .emphasis {
        //         font-size: 30px;
        //     }
        // }
    }

    p {
        margin: 1.5em 0 2em;
    }
}

.government-notice {
    color: #676767;
    font-size: 14px;
    line-height: 1.45em;
    margin-top: 10px;
    padding: 0;
}

.btn-link {
    background: none;
    color: #555;
    margin-bottom: -0.5rem;
    padding-right: 0;
    transition: 0.3s;

    &:hover,
    &:focus {
        box-shadow: none;
        text-decoration: none;

        .icon-arrow {
            left: 0.25em;
        }
    }

    .icon-arrow {
        left: 0;
        margin-left: 0.35em;
        top: -2px;
        transition: 0.3s;
    }
}

.form-check-input {
    &:focus {
        ~ .checkmark {
            border-color: #80bdff;
            box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
            outline: 0;
        }
    }
}

label {
    margin-bottom: 0.25rem;
}

.col-xl-1\.5 {
    flex: 0 0 12.5%;

    @media (max-width: map-get($grid-breakpoints, xl)) {
        flex: unset;
    }

    @media (max-width: map-get($grid-breakpoints, md)) {
        max-width: 50%;
    }

    @media (max-width: map-get($grid-breakpoints, sm)) {
        max-width: 100%;
        width: 100%;
    }
}

.example {
    max-width: 1200px;
    background: $white;
    margin-bottom: 20px;
}

// Pagination
.MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-regular.MuiTablePagination-toolbar.css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar p {
    margin: 0;
}

//Scrollbar behavior
.scrollable-box {
    height: 600px;
    overflow: auto;
}

// Section with blue top border
.section-box {
    position: relative;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
    padding: 20px;
    height: 800px;

    &:after {
        content: '';
        display: block;
        position: absolute;
        height: 8px;
        width: 100%;
        top: 0px;
        left: 0;
        transition: 0.3s;
        background-color: #3272a7;
        z-index: 1;
    }
}

// Gray section
.gray-box {
    background-color: #f4f3f3;
    padding: 10px 15px;
    border-radius: 5px;
    margin: 10px 0 30px 0;
    box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.15), 0 3px 1px -2px rgba(0, 0, 0, 0.12);
}

.box-border-top {
    // border: 1px solid #f2f2f2;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25), 0 1px 1px rgba(0, 0, 0, 0.15);
    border-top: 5px solid $softblue;
    padding: 20px;
}


// Toggle Button

.toggle-button {

    .toggle-button-label {
        color: #555;
        font-size: 14px;
        font-weight: 500;
    }

    .toggle-button-container {
        display: flex;
        overflow: hidden;
        width: 150px;
        height: 40px;
        position: relative;
        background-color: #f2f2f2;
        border-radius: 18px;
        box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.15);
        font-family: 'Roboto Condensed', 'Roboto', sans-serif;

        .toggle-button-option {
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            // font-size: 16px;
            font-weight: bold;
            color: #3272a7;
            cursor: pointer;
        }

        .toggle-button-option__label {
            z-index: 100;
        }

        .toggle-button-slider {
            position: absolute;
            top: 5px;
            left: 3px;
            width: 42%;
            height: 30px;
            background-color: #fff;
            border-radius: 14px;
            box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.15), 0 3px 1px -2px rgba(0, 0, 0, 0.12);
            transition: .3s;
        }
    }
}

// Radio Button
.radio-button {

    .radio-button-label {
        color: #555;
        font-size: 14px;
        font-weight: 500;
    }

    input[type="radio"] {
        margin-right: 5px;
    }

    label:not(:last-of-type) {
        margin-right: 15px;
    }

    label {
        font-weight: normal;
        cursor: pointer;
    }
}

// Select
.MuiSelect-select {
    padding: 4px 4px 4px 10px !important;
}

.count-icon {
    background: $blueTab;
    display: inline-block;
    color: #fff;
    border-radius: 100%;
    font-size: 14px;
    margin-left: 8px;
    min-width: 25px;
    text-align: center;
    padding: 4px 7px;
}

.css-w66kx-MuiChip-root {
    .count-icon {
        background: $blueTab;
        display: inline-block;
        color: #fff !important;
        border-radius: 100%;
        font-size: 14px;
        margin-left: 8px;
        min-width: 25px;
        text-align: center;
        padding: 4px 7px;
    }
}

.vertical-tabs-custom {
    .MuiTabs-indicator {
        display: none;
    }

    .Mui-selected {
        border-radius: 5px;
        background-color: #E6E6E9;
        color: #000 !important;

        &:focus {
            outline: none;
        }
    }
}



// Multiselect container
.multiselect-container {

    .sub-title {
        font-weight: bold;
        color: #000;
    }

    .multiselect-box {
        height: 295px;
        padding: 10px;
        overflow: auto;
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
        margin-top: 10px;
        border: 1px solid $gray-300;
        background-color: #f2f2f2;
        border-radius: 10px;

        .btn {
            position: relative;
            margin-left: 0;
            margin-bottom: 10px;
            text-align: left !important;
            padding: 10px 12px;
            background: #fff;
            border: none;
            // box-sizing: border-box;
            color: #555;
            box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.24), 0px 2px 2px 0px rgba(0, 0, 0, 0.15);

            &:hover,
            &:focus {
                // box-shadow: 0px 3px 8px -2px rgba(0, 0, 0, 0.5), 0px 2px 2px 0px rgba(0, 0, 0, 0.25);
                box-shadow: 0 3px 6px rgba(0, 0, 0, 0.4);
            }

            .count-icon {
                position: absolute;
                right: 5px;
                top: 6px;
                background-color: #dee3ef;
                color: #345d78;
                padding: 4px 10px;
            }
        }

        .selected {
            color: #3172a7;
            background-color: #dee3ef;

            .count-icon {
                background-color: #fff;
                color: #3172a7;
            }
        }
    }
}


.multiselect-multicolumns .multiselect-box {
    display: block;
    padding-right: 0;

    .btn {
        width: calc(50% - 10px);
        float: left;
        margin-right: 10px;
    }
}


// Centered Header for tables
.centered-header .MuiDataGrid-columnHeaderTitleContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.centered-cell .MuiDataGrid-cell {
    display: flex;
    align-items: center;
    justify-content: center;
}

.warning-message--top {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    padding: 5px 0;
    background-color: #F1F5F9;
    text-align: center;
    color: #20558A;
    font-size: 14px;
    font-weight: bold;
}



.datepicker_from-to {
    display: flex;
    justify-content: space-between;
}

.datepicker button.MuiIconButton-sizeMedium:focus {
    outline: none;
    background-color: none;
}
