.sidebar {
  background: #fff;
  box-shadow: 0 1px 12px 0 rgba(0, 0, 0, 0.15), 2px 0 1px -2px rgba(0, 0, 0, 0.12);
  color: #363b43;
  height: 100%;
  left: 0;
  min-height: calc(100vh - 155px);
  position: absolute;
  // top: 5px;
  // min-height: calc(100% - 155px);
  // padding: 20px;
  // padding-left: 50px;
  transition: 0.3s;
  width: $sidebar-width;
  z-index: 300;

  &.floating,
  &.floating.closed {
    position: fixed;
    top: 0;
  }

  // @media (max-width: 1250px) {
  //   min-height: 100vh;
  //   position: fixed;
  //   top: 0;
  //   z-index: 600;
  // }

  &.closed {
    left: -$sidebar-width;
    transition: 0.3s;

    .sidebar-trigger {
      img,
      svg {
        transform: rotate(180deg);
      }

      &.floating {
        left: -33px;
        top: 60px;
        transition: .3s;
        z-index: 501;

        &.solicitation-sidebar-trigger {
          left: -48px;
        }  
      }

      &.trigger-md.trigger-md-floating {
        left: -50px;
      }
    }

    // &.no-header-sidebar .sidebar-trigger{
    //   left: -33px;
    // }
  }

  .sidebar-content {
    height: 100%;
    overflow: auto;
    padding: 30px;

    form {
      .MuiFormControl-root {
        margin-bottom: 20px;

        >.MuiFormControl-root {
          margin-bottom: 0;
        }

        &.toggle-switch {
          margin-bottom: 4px;
        }

        &.calendar-parent .MuiInputAdornment-root {
          right: -5px;
          top: 16px;
        }

        img {
          width: 14px;
          margin-right: 10px;
        }
      }
    }
  }

  &.floating .sidebar-content {
    position: fixed;
    top: 0;
    width: 310px;
  }

  .form-control,
  label,
  .sidebar-trigger,
  .form-check-label {
    font-size: 14px;
  }

  .form-group {
    position: relative;
  }

  /* width */
  ::-webkit-scrollbar {
    width: 15px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #ebebeb;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #bbb;
    border: solid 1px #ebebeb;
  }

  .sidebar-trigger {
    background: rgb(255, 255, 255);
    background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 100%);
    border: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    box-shadow: 0 -1px 1px 0 rgba(44, 44, 44, 0.12), 4px -4px 4px 0 rgba(44, 44, 44, 0.08);
    color: $dark-gray;
    left: $trigger-left;
    padding-right: 2.25em;
    position: absolute;
    text-transform: uppercase;
    // box-shadow: 0px 1px 12px 0px rgba(0, 0, 0, 0.15), 2px 0px 1px -2px rgba(0, 0, 0, 0.12);
    top: 60px;
    transform: rotate(90deg);
    transition: 0.3s;
    // &.closed {
    //     img,
    //     svg {
    //         transform: rotate(180deg);
    //     }
    // }
    &:hover,
    &:focus {
      // box-shadow: 2px -2px 2px 0px rgba(44, 44, 44, 0.19);
      background: rgb(222, 227, 239);
      background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(235, 239, 249, 1) 100%);
      border: 0;
      box-shadow: 0 -1px 1px 0 rgba(44, 44, 44, 0.22), 4px -4px 4px 0 rgba(44, 44, 44, 0.08);
    }

    &.trigger-md {
      left: 261px;

      &.trigger-md-floating {
        position: fixed;
      }
    }

    img,
    svg {
      // display: inline-block;
      fill: #dee3ef;
      // margin-left: 8px;
      width: 16px;
      position: absolute;
      top: 0.5em;
      right: 0.5em;
    }

    @media (max-width: map-get($grid-breakpoints, md)) {
      top: 200px;
    }

    &.floating {
      position: fixed;
      top: 60px;
      z-index: 501;
    }
  }

  .form-check-label {
    font-weight: 500;
  }

  .MuiInputBase-multiline {
    padding-bottom: 0;
  }

  .form-check-label,
  .check-label {
    line-height: 1.2em;
    margin-bottom: 0.25rem;
    position: relative;

    .count {
      color: #757575;
      font-size: 13px;
      font-weight: 400;
      margin-left: 5px;
    }

    input {
      cursor: pointer;
      height: 0;
      opacity: 0;
      position: absolute;
      width: 0;
    }

    .checkmark {
      background-color: #eee;
      border-radius: 5px;
      height: 18px;
      left: 0;
      margin-left: -1.25rem;
      position: absolute;
      top: 0;
      transition: 0.3s;
      width: 18px;
    }

    input ~ .checkmark {
      background-color: #e7e6e2;
    }

    input:checked ~ .checkmark {
      background-color: #1a68a7;
      content: '\2713';
    }

    .checkmark::after {
      content: '';
      display: none;
      position: absolute;
    }

    input:checked ~ .checkmark::after {
      display: block;
    }

    .checkmark::after {
      border: solid $white;
      border-width: 0 2px 2px 0;
      height: 10px;
      left: 7px;
      top: 3px;
      width: 5px;
    }
  }
}

select {
  appearance: none;
  background: $bg-color;
  background-image: none;
  font-weight: 500;
  text-indent: 1px;
  text-overflow: '';
}

.select-arrow {
  fill: #999;
  height: 16px;
  pointer-events: none;
  position: absolute;
  right: 13px;
  stroke: #999;
  // top: 38px;
  width: 16px;
  z-index: 10;
}

// fixed sidebar on scroll
.fixed-sidebar {
  // left: -5px;
  position: fixed;
  top: 0;

  &.sidebar-trigger {
    position: fixed;
    top: 60px;
  }

  &.sidebar-inner {
    width: 250px;
  }
}

// sidebar actions
.sidebar-actions {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  justify-content: space-between;

  .MuiButtonBase-root {
    flex: 1 1 0;
    line-height: 1rem;
    padding: 8px 16px;
    font-size: 16px;
    text-transform: capitalize;

    &.btn-secondary {
      background: #757575;
      font-size: 16px;
    }

    &.btn-primary {
      background: $darkblue;
      color: white;
      font-size: 16px;
    }

    &:first-child {
      margin-right: 10px;
      font-size: 16px;
    }
  }
}

.sidebar-header {
    padding: 20px;
    padding-top: 15px;
    padding-left: 40px;
    background: white;
    position: relative;
    border-bottom: solid 2px #ddd;

    .proposals-header {
      font-size: 15px;
      font-weight: 500;
      padding-bottom: 10px;
    }

    .counters {
        display: flex;
        justify-content: space-between;

        .counter {
            box-shadow: 0 0 2px rgba(0, 0, 0, 0.38), 0 3px 10px rgba(0, 0, 0, 0.2);
            position: relative;
            border-radius: 5px;
            flex: 1 1 0;
            padding: 5px;
            text-align: center;
            transition: .3s;

            &::before {
                display: block;
                height: 3px;
                width: 100%;
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;
                position: absolute;
                top: 0;
                left: 0;
                content: '';
            }

            &.pending {
                &::before {
                    background: linear-gradient(#e6b229, #ffcc2f);
                }

                &.active {
                    background-color: darken(#e6b229, 5%);
                    color: #fff;

                    .counted {
                        -webkit-text-fill-color: #fff;
                    }
                }

                .counted {
                    background: linear-gradient(#e6b229, #ffcc2f);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }

            &.reviewed {
                &::before {
                    background: linear-gradient(#1f93be, #23a8d9);
                }

                .counted {
                    background-color: darken(#1f93be, 5%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }

                &.active {
                  background-color: darken(#1f93be, 5%);
                  color: #fff;

                  .counted {
                      -webkit-text-fill-color: #fff;
                  }
                }
            }

            &:hover,
            &:focus {
              box-shadow: 0 0 2px rgba(0, 0, 0, 0.4), 0 3px 10px rgba(0, 0, 0, 0.3);
              transition: .3s;
              cursor: pointer;
            }

            &:first-child {
                margin-right: 5px;
            }

            &:nth-child(2) {
                margin-left: 5px;
            }

            .counted {
                font-size: 28px;
                font-weight: 500;
            }
        }
    }

    form {
        margin-top: 15px;
    }

    .MuiFormControl-root {
        position: relative;

        label {
            color: $gray-900;
        }

        input {
            appearance: none;
            background: $bg-color;
            background-image: none;
            border-radius: 5px;
            font-size: 16px;
            font-weight: 400;
            margin-top: 5px;
            padding-left: 5px;
            padding-right: 5px;
            text-indent: 5px;
            text-overflow: '';
            width: 100%;

            &[readonly],
            &[disabled] {
                background: transparent;
                color: $black;
                opacity: 1;
                padding-left: 0;
                text-indent: 0;

                img {
                    display: none;
                }
            }
        }

        .MuiInput-root {
          &:before {
            // background-image: url(/images/search.svg);
            display: inline-block;
            height: 16px;
            right: 10px;
            position: absolute;
            top: 10px;
            width: 19px;
            content: '';
          }
        }


        // .search-icon {
        //   background-image: url(/images/search.svg);
        //   display: inline-block;
        //   height: 16px;
        //   right: 10px;
        //   position: absolute;
        //   top: 17px;
        //   width: 19px;
        //   content: '';
        // }
    }

    &.floating {
      box-shadow: 0 1px 12px 0 rgba(0, 0, 0, 0.15), 2px 0 1px -2px rgba(0, 0, 0, 0.12);
      position: fixed;
      top: 0;
      width: 310px;
      z-index: 500;
    }
}

.sidebar-list {
  height: calc(100% - 220px);
  max-height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  // project listings
  .sidebar-listing {
    border-bottom: solid 2px #ddd;
    padding: 10px 20px;
    padding-left: 40px;
    cursor: pointer;

    .list-head {
      font-size: 15px;
      line-height: 19px;
      font-weight: 500;
      margin-bottom: 10px;

      &:hover {
        color: #0580AD;
      }
    }

    .list-body {
      font-size: 13px;

      p {
        margin-bottom: 0;
      }
    }

    .list-footer {
      display: flex;
      margin-top: 10px;
      justify-content: space-between;
      align-items: center;

      .approval {
        .approval-status {
          display: flex;
          flex-direction: row;
          align-items: center;

          div {
            display: flex;
            flex-direction: column;
          }

          img {
            height: 27px;
            margin-right: 7px;
            position: relative;
            top: -1px;
          }
        }
      }

      small,
      p {
        margin-bottom: 0;
      }

      p {
        margin-top: -5px;

        &.hidden {
          display: none;
        }
      }

      .date {
        text-align: right;
        font-weight: 700;
      }
    }

    &.active-listing {
      border-color: $lightpurple;
      background: linear-gradient(#ddd9ee, #fff);

      .list-head {
        color: $lightpurple;
      }

      .list-footer {
        .approval {
          p {
            font-weight: bold;
            color: #6d6299;
            line-height: 1.8rem;
          }
        }
      }
    }
  }

  &:focus {
    outline: none;
  }
}